import { Dialog } from "primereact/dialog";
import { useMemo, useState } from "react";
import { ExcelIcon } from "@/helpers/svg-icons";
import { useFullComplaintsListExport } from "@/Service/Api/ApiHooks/Complaint/useFullComplaintsListExport";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { Button } from "primereact/button";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { KPISummaryExportMessage } from "@/Messages/Toast/Reports/KPISummaryExportMessage";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { useOrgId } from "@/Hooks/useOrgId";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { Tooltip } from "primereact/tooltip";
import { ExportKPISummaryReportRequest } from "@/stub";

type ComplaintsReportModalProps = {
    dateRangeFilter: Date[];
};

const ComplaintsReportModal = ({ dateRangeFilter }: ComplaintsReportModalProps) => {
    const orgId = useOrgId();
    const [visible, setVisible] = useState(false);
    const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
    const { mutateAsync, isPending } = useFullComplaintsListExport();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const requestParams = useMemo<ExportKPISummaryReportRequest>(() => ({
        from: formatToApiDate(dateRangeFilter?.[0]) as string,
        to: formatToApiDate(dateRangeFilter?.[1]),
        organisation_id: orgId,
        search: searchInput,
    }), [dateRangeFilter, orgId, searchInput]);

    const handleGenerateExport = async () => {
        await mutateAsync({
            ExportKPISummaryReportRequest: requestParams
        }, {
            onSuccess: () => {
                addToastMessage(KPISummaryExportMessage);
                setVisible(false);
                setSearchInput('');
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    return (
        <>
            <div>
                <Button id={'key-word-extract'} disabled={isPending} text className="fake-link ml-3"
                    onClick={() => setVisible(true)}>
                    <ExcelIcon/>
                </Button>
                <Tooltip
                    target="#key-word-extract"
                    content="Key Word Extract"
                    position="top"
                />
            </div>

            <Dialog draggable={false} header="Generate Complaints Report" visible={visible} style={{ width: '50vw' }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}>
                <div className="flex flex-column gap-3 w-full align-items-end">
                    <BaseTextInput
                        className='w-full'
                        pt={{
                            inputText: {
                                className: 'w-full'
                            }
                        }}
                        value={searchInput}
                        placeholder="Text"
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                        }}/>

                    <Button disabled={isPending} className="ml-3" onClick={handleGenerateExport}>
                        Generate Report
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export default ComplaintsReportModal;
