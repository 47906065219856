import '../styles/datatables.scss';
import KpiWorkInProgressDatatable from '../components/Datatables/KpiWorkInProgressDatatable';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTitle } from "react-use";

/**
 * KPI Work In Progress page
 */
const KpiWorkInProgressPage = (): JSX.Element => {
    useTitle('KPI Work In Progress');

    return (
        <>
            <div className="page-content">
                <KpiWorkInProgressDatatable />
            </div>
        </>
    );
};

export default withAuthenticationRequired(KpiWorkInProgressPage);
