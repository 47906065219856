/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRootCauseRequest
 */
export interface UpdateRootCauseRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateRootCauseRequest
     */
    organisation_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRootCauseRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRootCauseRequest
     */
    status?: UpdateRootCauseRequestStatusEnum;
}


/**
 * @export
 */
export const UpdateRootCauseRequestStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type UpdateRootCauseRequestStatusEnum = typeof UpdateRootCauseRequestStatusEnum[keyof typeof UpdateRootCauseRequestStatusEnum];


/**
 * Check if a given object implements the UpdateRootCauseRequest interface.
 */
export function instanceOfUpdateRootCauseRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateRootCauseRequestFromJSON(json: any): UpdateRootCauseRequest {
    return UpdateRootCauseRequestFromJSONTyped(json, false);
}

export function UpdateRootCauseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRootCauseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UpdateRootCauseRequestToJSON(value?: UpdateRootCauseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisation_id': value.organisation_id,
        'name': value.name,
        'status': value.status,
    };
}

