/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AdditionRole = {
    LetterDateHandler: 'forseti_letter_date_handler',
    FosHandler: 'forseti_fos_handler'
} as const;
export type AdditionRole = typeof AdditionRole[keyof typeof AdditionRole];


export function AdditionRoleFromJSON(json: any): AdditionRole {
    return AdditionRoleFromJSONTyped(json, false);
}

export function AdditionRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionRole {
    return json as AdditionRole;
}

export function AdditionRoleToJSON(value?: AdditionRole | null): any {
    return value as any;
}

