import React, { useId } from 'react';
import { FileUpload, type FileUploadProps } from 'primereact/fileupload';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import { Controller } from 'react-hook-form';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';
import { COMPLAINT_ALLOWED_EXTENSIONS } from "@/config/constants";

export type BaseFileUploadProps = FileUploadProps & BaseFormInputProps & {
    required?: boolean
};
const BaseFileUpload: React.FC<BaseFileUploadProps> = ({
    control,
    label,
    errorMessages,
    className,
    required,
    ...props
}: BaseFileUploadProps) => {
    const localId = useId();

    return (
        <div className={className}>
            <label className="block mb-2" htmlFor={localId}>{label}{required ? '*' : ''}</label>
            {
                control && props.name &&
                <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field }) => (
                        <FileUpload
                            id={localId}
                            name={field.name}
                            disabled={field.disabled}
                            onSelect={(e) => {
                                field.onChange(e.files);
                                if (props.onSelect) {
                                    props.onSelect(e);
                                }
                            }}
                            onBeforeSelect={(e) => {
                                field.onBlur();
                                if (props.onBeforeSelect) {
                                    props.onBeforeSelect(e);
                                }
                            }}
                            accept={props.accept ?? COMPLAINT_ALLOWED_EXTENSIONS.join(',')}
                            chooseOptions={props.chooseOptions ?? { label: 'Upload', icon: 'pi pi-cloud-upload' }}
                            {...props}
                        />
                    )}
                />
            }
            {
                !control &&
                <FileUpload
                    id={localId}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseFileUpload;
