import React, { useMemo } from 'react';
import { FilterOptions } from "@/Enums/FilterOptions";
import BaseRootCauseSelect from "@/components/Core/Form/Selector/BaseRootCauseSelect";
import { TreeSelectChangeEvent } from "primereact/treeselect";
import BaseProductSelect from "@/components/Core/Form/Selector/BaseProductSelect";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import CustomerSelect from "@/components/Core/Form/Selector/CustomerSelect";
import UserSelect from "@/components/Core/Form/Selector/UserSelect";
import { checkPermission } from "@/Util/permissionChecks";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { SelectItem } from "primereact/selectitem";
import { ComplaintStateEnum, Customer, User } from "@/stub";
import { Button } from "primereact/button";
import { useAuthUser } from "@/Hooks/useAuthUser";
import useGetInboxFiltersQuery, { InboxFilterType } from "@/Hooks/useGetInboxFiltersQuery";
import { useGetCustomer } from "@/Service/Api/ApiHooks/Customer/useGetCustomer";
import { useGetUser } from "@/Service/Api/ApiHooks/Users/useGetUser";
import { useSearchParams } from "react-router-dom";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { useGetProduct } from "@/Service/Api/ApiHooks/Product/useGetProduct";
import { useUpdateSearchParams } from "@/Hooks/useUpdateSearchParams";

export type FilterOption = {
    type: FilterOptions,
    value: string
};

export type FiltersListProps = {
    className?: string
    availableStatuses?: SelectItem[],
    filterOptions: FilterOption[]
};

export type TableFilters = {
    date_range: Date[],
    customer?: Customer,
    owner?: User,
    status?: ComplaintStateEnum
};

const InboxFilterPresetsList: React.FC<FiltersListProps> = ({
    className,
    availableStatuses,
    filterOptions
}: FiltersListProps) => {
    const authUser = useAuthUser();
    const [searchParams, setSearchParams] = useSearchParams();

    const resetTableFilters = () => {
        searchParams.delete(InboxFilterType.DateRangeFrom);
        searchParams.delete(InboxFilterType.DateRangeTo);
        searchParams.delete(InboxFilterType.CustomerId);
        searchParams.delete(InboxFilterType.OwnerId);
        searchParams.delete(InboxFilterType.States);
        searchParams.delete(InboxFilterType.ProductId);
        searchParams.delete(InboxFilterType.RootCauseId);
        searchParams.delete(InboxFilterType.Presets);
        searchParams.delete(InboxFilterType.Search);
        searchParams.delete(InboxFilterType.PageSize);
        searchParams.delete(InboxFilterType.Page);
        setSearchParams(searchParams, { replace: true });
    };

    const availableFilters = useMemo<FilterOption[]>(() => {
        const options: FilterOption[] = [];
        filterOptions.forEach(filter => {
            switch (filter.type) {
                case FilterOptions.Customer:
                    if (checkPermission(authUser?.permissions, 'read:org_customers')) {
                        options.push(filter);
                    }
                    break;


                default:
                    options.push(filter);
                    break;
            }
        });
        return options;
    }, [authUser?.permissions, filterOptions]);


    const updateFilterHandler = useUpdateSearchParams();
    const {
        customerQuery,
        rootCauseIdQuery,
        statesQuery,
        productIdQuery,
        ownerQuery,
        dataRangeQueryTo,
        dataRangeQueryFrom
    } = useGetInboxFiltersQuery();



    const { data: customer } = useGetCustomer({
        requestParams: {
            customer_id: customerQuery
        },
        enabled: !!customerQuery
    });

    const { data: user } = useGetUser({
        requestParams: {
            user_id: +ownerQuery
        },
        enabled: !!ownerQuery
    });

    const { data: product } = useGetProduct({
        requestParams: {
            product_id: +productIdQuery
        },
        enabled: !!productIdQuery
    });


    return (
        <div className="flex justify-content-between">
            <div className={className}>
                {
                    availableFilters.map(
                        option => {
                            switch (option.type) {
                                case FilterOptions.DateRange:
                                    const value = dataRangeQueryFrom || dataRangeQueryTo ? [!!dataRangeQueryFrom ? new Date(dataRangeQueryFrom) : new Date(), dataRangeQueryTo ? new Date(dataRangeQueryTo) : null] : [];
                                    return <BaseCalendar
                                        key={option.type}
                                        value={value}
                                        selectionMode='range'
                                        dateFormat='dd/mm/yy'
                                        placeholder={FilterOptions.DateRange}
                                        showButtonBar
                                        selectOtherMonths
                                        readOnlyInput
                                        hideOnRangeSelection
                                        onClearButtonClick={() => {
                                            searchParams.delete(InboxFilterType.DateRangeFrom);
                                            searchParams.delete(InboxFilterType.DateRangeTo);
                                            setSearchParams(searchParams, { replace: true });
                                        }}
                                        onChange={(event) => {
                                            if (event.value === null) {
                                                searchParams.delete(InboxFilterType.DateRangeFrom);
                                                searchParams.delete(InboxFilterType.DateRangeTo);
                                                setSearchParams(searchParams, { replace: true });
                                            }
                                            updateFilterHandler(InboxFilterType.DateRangeFrom, formatToApiDate(event.value[0]));
                                            updateFilterHandler(InboxFilterType.DateRangeTo, formatToApiDate(event.value[1]));
                                        }
                                        }

                                    />;

                                case FilterOptions.Customer:
                                    return <CustomerSelect
                                        key={option.type}
                                        value={customer}
                                        placeholder={FilterOptions.Customer}
                                        onClear={() => {
                                            searchParams.delete(InboxFilterType.CustomerId);
                                            setSearchParams(searchParams, { replace: true });
                                        }}
                                        onSelect={(event) => {
                                            updateFilterHandler(InboxFilterType.CustomerId, event.value.id);
                                        }}
                                    />;

                                case FilterOptions.Owner:
                                    return (
                                        <UserSelect
                                            key={option.type}
                                            value={user}
                                            placeholder={FilterOptions.Owner}
                                            onClear={() => {
                                                searchParams.delete(InboxFilterType.OwnerId);
                                                setSearchParams(searchParams, { replace: true });
                                            }}
                                            onSelect={(event) => {
                                                updateFilterHandler(InboxFilterType.OwnerId, event.value.id);
                                            }}
                                        />
                                    );

                                case FilterOptions.RootCause:
                                    return <BaseRootCauseSelect
                                        key={option.type}
                                        value={rootCauseIdQuery}
                                        className="rounded-md"
                                        placeholder={FilterOptions.RootCause}
                                        showClear
                                        onChange={(event: TreeSelectChangeEvent) => {
                                            updateFilterHandler(InboxFilterType.RootCauseId, event.value);
                                        }}
                                    />;

                                case FilterOptions.Product:
                                    return <BaseProductSelect
                                        key={option.type}
                                        selectedProduct={product}
                                        value={+productIdQuery || null}
                                        placeholder={FilterOptions.Product}
                                        showClear
                                        onChange={(event: TreeSelectChangeEvent) => {
                                            updateFilterHandler(InboxFilterType.ProductId, event.value);
                                        }}
                                    />;


                                case FilterOptions.Status:
                                    return <BaseDropdown
                                        key={option.type}
                                        value={statesQuery}
                                        options={availableStatuses}
                                        showClear
                                        placeholder={FilterOptions.Status}
                                        onChange={(event) => {
                                            updateFilterHandler(InboxFilterType.States, event.value);
                                        }}
                                    />;
                            }
                        })
                }
            </div>
            <div>
                <Button
                    label="Reset"
                    onClick={resetTableFilters}
                />
            </div>
        </div>
    );
};

export default InboxFilterPresetsList;
