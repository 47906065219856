import React from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import CustomerForm, { CustomerFormProps } from "@/components/Core/Customer/CustomerForm";
import { createGlobalStyle } from "styled-components";

export type CustomerModalProps = {
    dialogProps: DialogProps
    customerFormProps: CustomerFormProps
};

const CustomerFormModalStyle = createGlobalStyle`
    .customer-form {
        max-width: 600px;

        .p-inputwrapper {
            width: 100%;
        }
    }
`;

const CustomerModal: React.FC<CustomerModalProps> = ({ dialogProps, customerFormProps }: CustomerModalProps) => {

    return (
        <>
            <CustomerFormModalStyle/>
            <Dialog
                draggable={false}
                blockScroll
                header="Customer information"
                pt={{
                    content: {
                        className: 'align-items-start'
                    }
                }}
                {...dialogProps}
            >
                <CustomerForm
                    {...customerFormProps}
                />
            </Dialog>
        </>
    );
};

export default CustomerModal;
