import { useQuery } from '@tanstack/react-query';
import { GetOrganisationRequest } from '@/stub';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { organisationsApiClient } from "@/Service/Api/ApiClients/OrganisationsApiClient";

export type useGetOrganisationParams = {
    requestParams: GetOrganisationRequest
    enabled?: boolean
};

export const useGetOrganisation = ({ enabled = true, requestParams = {} }: useGetOrganisationParams) => useQuery({
    ...QueryKeys.organisations.detail(requestParams.organisation_id),
    queryFn: async () => await organisationsApiClient.getOrganisation(requestParams),
    enabled,
});
