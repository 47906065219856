/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentResource } from './DocumentResource';
import {
    DocumentResourceFromJSON,
    DocumentResourceFromJSONTyped,
    DocumentResourceToJSON,
} from './DocumentResource';
import type { OAFosState } from './OAFosState';
import {
    OAFosStateFromJSON,
    OAFosStateFromJSONTyped,
    OAFosStateToJSON,
} from './OAFosState';

/**
 * 
 * @export
 * @interface ComplaintFos
 */
export interface ComplaintFos {
    /**
     * 
     * @type {number}
     * @memberof ComplaintFos
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintFos
     */
    is_fos_notification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintFos
     */
    is_fos?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    case_requested_date_received?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    case_requested_date_due?: Date;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    case_review?: ComplaintFosCaseReviewEnum;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    case_review_summary?: string;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    case_submitted?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    adjudication_date_received?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    adjudication_outcome?: ComplaintFosAdjudicationOutcomeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    adjudication_outcome_summary?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    adjudication_date_request_final_decision?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintFos
     */
    final_decision_requested?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    final_decision_date_requested?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    final_decision_requested_by?: ComplaintFosFinalDecisionRequestedByEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    escalation_reason?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    further_info_due_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    further_info_date_submitted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    further_info_summary?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    final_decision_date_received?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    final_decision_outcome?: ComplaintFosFinalDecisionOutcomeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    final_decision_outcome_summary?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintFos
     */
    is_redress?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComplaintFos
     */
    redress?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComplaintFos
     */
    other_remedial?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    remedial_summary?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    remedial_date_due?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    remedial_date_completed?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ComplaintFos
     */
    created_at?: Date;
    /**
     * 
     * @type {DocumentResource}
     * @memberof ComplaintFos
     */
    document?: DocumentResource;
    /**
     * 
     * @type {OAFosState}
     * @memberof ComplaintFos
     */
    state?: OAFosState;
    /**
     * 
     * @type {string}
     * @memberof ComplaintFos
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ComplaintFos
     */
    days_open?: number;
}


/**
 * @export
 */
export const ComplaintFosCaseReviewEnum = {
    ChallengeJurisdiction: 'challenge_jurisdiction',
    OfferToSettle: 'offer_to_settle',
    DefendFrlOutcome: 'defend_frl_outcome'
} as const;
export type ComplaintFosCaseReviewEnum = typeof ComplaintFosCaseReviewEnum[keyof typeof ComplaintFosCaseReviewEnum];

/**
 * @export
 */
export const ComplaintFosAdjudicationOutcomeEnum = {
    Business: 'agree_with_business',
    Customer: 'agree_with_customer'
} as const;
export type ComplaintFosAdjudicationOutcomeEnum = typeof ComplaintFosAdjudicationOutcomeEnum[keyof typeof ComplaintFosAdjudicationOutcomeEnum];

/**
 * @export
 */
export const ComplaintFosFinalDecisionRequestedByEnum = {
    Customer: 'customer',
    Business: 'business'
} as const;
export type ComplaintFosFinalDecisionRequestedByEnum = typeof ComplaintFosFinalDecisionRequestedByEnum[keyof typeof ComplaintFosFinalDecisionRequestedByEnum];

/**
 * @export
 */
export const ComplaintFosFinalDecisionOutcomeEnum = {
    Business: 'agree_with_business',
    Customer: 'agree_with_customer'
} as const;
export type ComplaintFosFinalDecisionOutcomeEnum = typeof ComplaintFosFinalDecisionOutcomeEnum[keyof typeof ComplaintFosFinalDecisionOutcomeEnum];


/**
 * Check if a given object implements the ComplaintFos interface.
 */
export function instanceOfComplaintFos(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComplaintFosFromJSON(json: any): ComplaintFos {
    return ComplaintFosFromJSONTyped(json, false);
}

export function ComplaintFosFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplaintFos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'is_fos_notification': !exists(json, 'is_fos_notification') ? undefined : json['is_fos_notification'],
        'is_fos': !exists(json, 'is_fos') ? undefined : json['is_fos'],
        'case_requested_date_received': !exists(json, 'case_requested_date_received') ? undefined : (new Date(json['case_requested_date_received'])),
        'case_requested_date_due': !exists(json, 'case_requested_date_due') ? undefined : (new Date(json['case_requested_date_due'])),
        'case_review': !exists(json, 'case_review') ? undefined : json['case_review'],
        'case_review_summary': !exists(json, 'case_review_summary') ? undefined : json['case_review_summary'],
        'case_submitted': !exists(json, 'case_submitted') ? undefined : (new Date(json['case_submitted'])),
        'adjudication_date_received': !exists(json, 'adjudication_date_received') ? undefined : (json['adjudication_date_received'] === null ? null : new Date(json['adjudication_date_received'])),
        'adjudication_outcome': !exists(json, 'adjudication_outcome') ? undefined : json['adjudication_outcome'],
        'adjudication_outcome_summary': !exists(json, 'adjudication_outcome_summary') ? undefined : json['adjudication_outcome_summary'],
        'adjudication_date_request_final_decision': !exists(json, 'adjudication_date_request_final_decision') ? undefined : (json['adjudication_date_request_final_decision'] === null ? null : new Date(json['adjudication_date_request_final_decision'])),
        'final_decision_requested': !exists(json, 'final_decision_requested') ? undefined : json['final_decision_requested'],
        'final_decision_date_requested': !exists(json, 'final_decision_date_requested') ? undefined : (json['final_decision_date_requested'] === null ? null : new Date(json['final_decision_date_requested'])),
        'final_decision_requested_by': !exists(json, 'final_decision_requested_by') ? undefined : json['final_decision_requested_by'],
        'escalation_reason': !exists(json, 'escalation_reason') ? undefined : json['escalation_reason'],
        'further_info_due_date': !exists(json, 'further_info_due_date') ? undefined : (json['further_info_due_date'] === null ? null : new Date(json['further_info_due_date'])),
        'further_info_date_submitted': !exists(json, 'further_info_date_submitted') ? undefined : (json['further_info_date_submitted'] === null ? null : new Date(json['further_info_date_submitted'])),
        'further_info_summary': !exists(json, 'further_info_summary') ? undefined : json['further_info_summary'],
        'final_decision_date_received': !exists(json, 'final_decision_date_received') ? undefined : (json['final_decision_date_received'] === null ? null : new Date(json['final_decision_date_received'])),
        'final_decision_outcome': !exists(json, 'final_decision_outcome') ? undefined : json['final_decision_outcome'],
        'final_decision_outcome_summary': !exists(json, 'final_decision_outcome_summary') ? undefined : json['final_decision_outcome_summary'],
        'is_redress': !exists(json, 'is_redress') ? undefined : json['is_redress'],
        'redress': !exists(json, 'redress') ? undefined : json['redress'],
        'other_remedial': !exists(json, 'other_remedial') ? undefined : json['other_remedial'],
        'remedial_summary': !exists(json, 'remedial_summary') ? undefined : json['remedial_summary'],
        'remedial_date_due': !exists(json, 'remedial_date_due') ? undefined : (json['remedial_date_due'] === null ? null : new Date(json['remedial_date_due'])),
        'remedial_date_completed': !exists(json, 'remedial_date_completed') ? undefined : (json['remedial_date_completed'] === null ? null : new Date(json['remedial_date_completed'])),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'document': !exists(json, 'document') ? undefined : DocumentResourceFromJSON(json['document']),
        'state': !exists(json, 'state') ? undefined : OAFosStateFromJSON(json['state']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'days_open': !exists(json, 'days_open') ? undefined : json['days_open'],
    };
}

export function ComplaintFosToJSON(value?: ComplaintFos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_fos_notification': value.is_fos_notification,
        'is_fos': value.is_fos,
        'case_requested_date_received': value.case_requested_date_received === undefined ? undefined : (value.case_requested_date_received.toISOString().substring(0,10)),
        'case_requested_date_due': value.case_requested_date_due === undefined ? undefined : (value.case_requested_date_due.toISOString().substring(0,10)),
        'case_review': value.case_review,
        'case_review_summary': value.case_review_summary,
        'case_submitted': value.case_submitted === undefined ? undefined : (value.case_submitted.toISOString().substring(0,10)),
        'adjudication_date_received': value.adjudication_date_received === undefined ? undefined : (value.adjudication_date_received === null ? null : value.adjudication_date_received.toISOString().substring(0,10)),
        'adjudication_outcome': value.adjudication_outcome,
        'adjudication_outcome_summary': value.adjudication_outcome_summary,
        'adjudication_date_request_final_decision': value.adjudication_date_request_final_decision === undefined ? undefined : (value.adjudication_date_request_final_decision === null ? null : value.adjudication_date_request_final_decision.toISOString().substring(0,10)),
        'final_decision_requested': value.final_decision_requested,
        'final_decision_date_requested': value.final_decision_date_requested === undefined ? undefined : (value.final_decision_date_requested === null ? null : value.final_decision_date_requested.toISOString().substring(0,10)),
        'final_decision_requested_by': value.final_decision_requested_by,
        'escalation_reason': value.escalation_reason,
        'further_info_due_date': value.further_info_due_date === undefined ? undefined : (value.further_info_due_date === null ? null : value.further_info_due_date.toISOString().substring(0,10)),
        'further_info_date_submitted': value.further_info_date_submitted === undefined ? undefined : (value.further_info_date_submitted === null ? null : value.further_info_date_submitted.toISOString().substring(0,10)),
        'further_info_summary': value.further_info_summary,
        'final_decision_date_received': value.final_decision_date_received === undefined ? undefined : (value.final_decision_date_received === null ? null : value.final_decision_date_received.toISOString().substring(0,10)),
        'final_decision_outcome': value.final_decision_outcome,
        'final_decision_outcome_summary': value.final_decision_outcome_summary,
        'is_redress': value.is_redress,
        'redress': value.redress,
        'other_remedial': value.other_remedial,
        'remedial_summary': value.remedial_summary,
        'remedial_date_due': value.remedial_date_due === undefined ? undefined : (value.remedial_date_due === null ? null : value.remedial_date_due.toISOString().substring(0,10)),
        'remedial_date_completed': value.remedial_date_completed === undefined ? undefined : (value.remedial_date_completed === null ? null : value.remedial_date_completed.toISOString().substring(0,10)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString().substring(0,10)),
        'document': DocumentResourceToJSON(value.document),
        'state': OAFosStateToJSON(value.state),
        'status': value.status,
        'days_open': value.days_open,
    };
}

