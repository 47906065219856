import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
    GetRootCauseInTreeStructureRequest,
    GetRootCausesActivityLogListRequest,
    GetRootCausesConfigDownloadDetailsRequest,
    GetRootCausesLatestVersionRequest
} from '@/stub';

export const RootCauses = createQueryKeys('rootCauses', {
    list: (requestParams?: GetRootCauseInTreeStructureRequest) => [{ requestParams }],
    activityLog: (requestParams?: GetRootCausesActivityLogListRequest) => [{ requestParams }],
    detail: (rootCauseId: number) => [rootCauseId],
    configVersion: (requestParams?: GetRootCausesLatestVersionRequest) => [{ requestParams }],
    configDownloadDetails: (requestParams?: GetRootCausesConfigDownloadDetailsRequest) => [{ requestParams }],
});
