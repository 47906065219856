import { Column, ColumnBodyOptions } from 'primereact/column';
import React, { useMemo, useState } from 'react';
import { DT_ROWS_NO } from '@/config/constants';
import styled from 'styled-components';
import { DataTable, type DataTablePassThroughOptions, type DataTableStateEvent } from 'primereact/datatable';
import {
    type ActivityLog,
    ComplaintAudit,
    GetRootCausesActivityLogListRequest,
    SortBy,
    SortOrder as SortOrderAudit,
} from '@/stub';
import { SortOrder } from 'primereact/api';
import { produce } from 'immer';
import AnglesSort from '@/components/Icons/AnglesSort';
import { Button } from "primereact/button";
import useGetAuditFiltersQuery, { AuditFilterType } from "@/Hooks/useGetAuditFiltersQuery";
import { useUpdateSearchParams } from "@/Hooks/useUpdateSearchParams";
import { Dialog } from "primereact/dialog";
import AuditPreview from "@/components/Core/Audit/AuditPreview";
import { getLabelForLogsEvent } from "@/components/Filters/Audit/AuditFilterList";
import { formatToUKDate } from "@/Util/formatToUKDate";
import { useGetRootCauseActivityLogs } from "@/Service/Api/ApiHooks/RootCause/useGetRootCauseActivityLogs";
import { useRestoreRootCausesVersion } from "@/Service/Api/ApiHooks/RootCause/useRestoreRootCausesVersion";
import { useQueryClient } from "@tanstack/react-query";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { useGetRootCausesConfigVersion } from "@/Service/Api/ApiHooks/RootCause/useGetRootCausesConfigVersion";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { RootCauseVersionRestoredMessage } from "@/Messages/Toast/RootCauses/RootCauseVersionRestoredMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";

type TableFilterOptions = {
    first: number
    rows: number
    sortField: string
    sortOrder: SortOrder
    search: string
};

type TableColumnDefinition = {
    label: string
    sortable?: boolean
    field?: string
    sortField?: string
    headerTooltip?: string
    body?: React.ReactNode | ((data: ActivityLog, options: ColumnBodyOptions) => React.ReactNode)
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .content-header {
        padding: 1rem;
        border-bottom: var(--gray-300) 1px solid;

        &__title {
            margin: 0.25rem 0;
            font-weight: 600;
            font-size: 1.5rem;
        }

        &__description {
            font-size: 0.9rem;
            color: var(--gray-500);
        }
    }

    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;

        button {
            max-width: 5.5rem;
            max-height: 3rem;
            font-size: 1rem;
        }
    }

    .datatable-cell {
        &__clean-text {
            margin: 0;
        }
    }

    .angles-sort {
        margin-left: 0.3rem;

        &__icon {
            color: var(--primary-200);
            font-size: 0.8rem;
            font-weight: 600;

            &.active {
                color: var(--primary-500);
            }
        }
    }
`;

const getSortOrder = (sortOrderQuery: string | number | null): SortOrder => {
    const order = Number(sortOrderQuery);
    if (order === 1 || order === -1) {
        return order as SortOrder;
    }
    return -1;
};


const RootCauseAuditDatatable = () => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const { mutate: restoreVersion, isPending: isRestorePending } = useRestoreRootCausesVersion();
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        search: '',
        sortField: 'created_at',
        sortOrder: SortOrder.DESC,
        rows: DT_ROWS_NO,
        first: 0
    });

    const updateAuditFilterHandler = useUpdateSearchParams();
    const {
        pageQuery,
        pageSizeQuery,
        sortOrderQuery,
        sortFieldQuery,
    } = useGetAuditFiltersQuery();

    const auditsRequest = useMemo<GetRootCausesActivityLogListRequest>(() => {
        return {
            sort_by: sortFieldQuery as SortBy ?? undefined,
            sort_order: Number(sortOrderQuery) === SortOrder.ASC ? SortOrderAudit.Asc : tableFilters.sortOrder === SortOrder.DESC ? SortOrderAudit.Desc : undefined,
            page: pageQuery ? parseInt(pageQuery) : 1,
            limit: pageSizeQuery ? parseInt(pageSizeQuery) : 10
        };
    }, [sortOrderQuery, sortFieldQuery, tableFilters.sortOrder, pageQuery, pageSizeQuery]);

    const {
        data: audits,
        isLoading: auditsLoading
    } = useGetRootCauseActivityLogs({
        requestParams: auditsRequest
    });

    const { data: configVersionData } = useGetRootCausesConfigVersion({});

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
        updateAuditFilterHandler(AuditFilterType.Page, ((e.first / e.rows) + 1).toString());
        updateAuditFilterHandler(AuditFilterType.PageSize, e.rows.toString());
    };

    const onSort = (e: DataTableStateEvent) => {
        updateAuditFilterHandler(AuditFilterType.SortField, e.sortField);
        updateAuditFilterHandler(AuditFilterType.SortOrder, e.sortOrder ?? SortOrder.UNSORTED);
        setTableFilters(produce(draft => {
            draft.sortField = e.sortField;
            draft.sortOrder = e.sortOrder ?? SortOrder.UNSORTED;
        }));
    };

    const onRestoreVersion = (version: string) => {
        confirmDialog({
            header: 'Confirmation needed!',
            message: "Please confirm to proceed moving forward. This action will be processed in background",
            accept: () => {
                restoreVersion({
                    RestoreRootCauseVersionRequest: {
                        version: version
                    }
                }, {
                    onSuccess: () => {
                        addToastMessage(RootCauseVersionRestoredMessage);
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.rootCauses._def
                        });
                    },
                    onError: error => {
                        addToastMessage(CustomErrorMessage(error));
                    }
                });
            },
            reject: () => {

            }
        });
    };

    /**
     * Actions column template
     *
     * @param {Audit} audit object containing row data
     */
    const ActionsBodyTemplate = (audit: ActivityLog) => {
        const [visible, setVisible] = useState(false);
        const version = audit.properties?.attributes?.version ?? null;

        return (
            <div className="flex gap-1">
                <Button
                    onClick={() => setVisible(true)}
                    loading={isRestorePending}
                    label='View'
                />
                {!!version && version !== configVersionData?.version ? <Button
                    onClick={() => {
                        onRestoreVersion(version);
                    }}
                    label={'Apply version'}
                    severity={'warning'}
                    loading={isRestorePending}
                />
                    : undefined}

                <Dialog header="Detailed information" draggable={false} resizable={false} visible={visible}
                    style={{ width: '50vw' }} onHide={() => {
                        setVisible(false);
                    }}>
                    <AuditPreview audit={audit as ComplaintAudit}/>
                </Dialog>
            </div>
        );
    };


    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'Date Time',
            sortable: true,
            field: 'created_at',
            body: (activityData) => {
                return <span style={{ textWrap: "nowrap" }}>
                    {formatToUKDate(activityData.created_at, true)}
                </span>;

            }
        },
        {
            label: 'User',
            field: 'user.display_name',
            body: (activityData) => {
                return <>
                    {activityData.user?.display_name ?? 'N/A'}
                </>;

            }
        },
        {
            label: 'Action',
            body: (activityData) => {
                return <>
                    {activityData.event ? getLabelForLogsEvent(activityData.event) : 'N/A'}
                </>;

            }
        },
        {
            label: 'Description',
            body: (activityData) => {
                return <>
                    {activityData.description ?? 'N/A'}
                </>;
            }
        },
        {
            label: 'Version',
            body: (activityData) => {
                return <>
                    {activityData.properties?.attributes?.version ?? 'N/A'}
                </>;
            }
        },
        {
            label: '',
            body: ActionsBodyTemplate
        }
    ];

    const first = pageSizeQuery && pageQuery ? Number(pageSizeQuery) * Number(pageQuery) - 10 : 0;
    const rows = pageSizeQuery ? Number(pageSizeQuery) : 10;

    return (
        <StyledWrap>
            <DataTable
                lazy
                loading={auditsLoading}
                emptyMessage="No activity log found."
                value={audits?.data}
                totalRecords={audits?.meta.total}
                paginator
                rows={rows}
                rowsPerPageOptions={[10, 20, 50]}
                first={first}
                pt={dataTablePtOptions}
                sortIcon={(options) => {
                    return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                }}
                sortField={sortFieldQuery || 'created_at'}
                sortOrder={getSortOrder(sortOrderQuery)}
                onPage={e => {
                    onPagination(e);
                }}
                onSort={e => {
                    onSort(e);
                }}
            >
                {tableColumns.map((column, index) =>
                    <Column
                        key={`audits-column-${index}`}
                        field={column.field}
                        body={column.body}
                        header={column.label}
                        sortable={column.sortable}
                        sortField={column.sortField}
                        headerTooltip={column.headerTooltip}
                    />
                )}
            </DataTable>
            <ConfirmDialog/>
        </StyledWrap>
    );
};

export default RootCauseAuditDatatable;
