import '@/themes/default/styles/page-layout.scss';
import { type MutableRefObject, type ReactNode, useEffect, useRef } from 'react';
import Header from '@/components/Blocks/Header';
import ContentLoadingTemplate from '@/components/templates/ContentLoadingTemplate';
import Sidebar from '@/components/Blocks/Sidebar';
import { Toast } from 'primereact/toast';
import { useAuth0 } from '@auth0/auth0-react';
import registerServiceWorker from '@/service-worker';
import DefaultGlobalToast from '@/components/Notifications/DefaultGlobalToast';

const appVersion = import.meta.env.VITE_APP_VERSION;
const today = new Date();

type PageLayoutProps = {
    children?: ReactNode
    loadingContent?: boolean
};

const PageLayout = ({ children, loadingContent }: PageLayoutProps) => {
    const toastRef: MutableRefObject<void> = useRef();
    const { getAccessTokenSilently } = useAuth0();
    const toastANRef = useRef();

    const registerSw = async () => {
        const accessToken = await getAccessTokenSilently();
        registerServiceWorker(toastANRef, accessToken);
    };

    useEffect(() => {
        registerSw();
    }, []);

    return (
        <>
            <div className="page-layout">
                <div className="header-wrapper">
                    <div className="main-container h-full">
                        <Header/>
                    </div>
                </div>

                <div className="main-container h-full">
                    <div className="grid grid-nogutter flex-nowrap h-full">
                        <div className="sidebar-column">
                            <Sidebar />
                        </div>

                        <div className="col main-content-column">
                            {children}

                            {loadingContent && <ContentLoadingTemplate />}
                        </div>
                    </div>
                </div>

                <div className="main-footer">
                    <div className="grid grid-nogutter">
                        <div className="col">
                            <div className="text-center my-4">
                                <span className="text-sm">&copy; Forseti by Bridgetech Group {today.getFullYear()}. {`Version ${appVersion}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DefaultGlobalToast/>
            <Toast ref={toastRef}/>
            <Toast ref={toastANRef} className="cookies-notification" position="top-left"/>
        </>
    );
};

export default PageLayout;
