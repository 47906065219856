import { useMutation } from "@tanstack/react-query";
import { CloseComplaintAllActionRequest } from "@/stub";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";
import { ComplaintActionsApiClient } from "@/Service/Api/ApiClients/ComplaintActionsApiСlient";

export const useUpdateComplaintAllActions = () => {
    return useMutation({
        mutationFn: async (data: CloseComplaintAllActionRequest) => {
            try {
                return await ComplaintActionsApiClient.closeComplaintAllAction(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
