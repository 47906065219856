import React from 'react';
import { FilterOptions } from "@/Enums/FilterOptions";
import { RootCauseStatus } from "@/stub";
import { SelectItem } from "primereact/selectitem";
import FiltersList, { FilterOption } from "@/components/Filters/FiltersList";
import { snakeToNormalCase } from "@/helpers/general";

export type RootCauseFiltersListProps = {
    className?: string
};

export const availableStatuses = (): SelectItem[] => {
    return Object.entries(RootCauseStatus)
        .map(([_, value]) => ({
            value: value,
            label: snakeToNormalCase(value.toLowerCase())
        }));
};


//Declare here all possible filters that you want to see on the page,
//then needed permissions will be checked to see if filter can be displayed or not for current authenticated user
const allAvailableInboxPresets: FilterOption[] = [
    {
        type: FilterOptions.Status,
        value: 'status'
    }
];

const RootCauseFiltersList: React.FC<RootCauseFiltersListProps> = ({
    className
}: RootCauseFiltersListProps) => {
    return <FiltersList
        className={className}
        availableStatuses={availableStatuses()}
        filterOptions={allAvailableInboxPresets}
    />;
};

export default RootCauseFiltersList;
