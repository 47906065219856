import React, { type ReactNode, useMemo } from 'react';
import { useGetChannelConfig } from '@/Service/Api/ApiHooks/ComplaintChannel/useGetChannelConfig';
import BaseDropdown from '@/components/Core/Form/BaseDropdown';
import { type ChannelConfig, ChannelDocument } from '@/stub';
import BaseTextInput from '@/components/Core/Form/BaseTextInput';
import { type Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { type FieldErrors } from 'react-hook-form/dist/types/errors';
import BaseTextEditor from "@/components/Core/Form/BaseTextEditor";
import AdvancedFileUpload from "@/components/Core/Form/FileUpload/AdvancedFileUpload";
import { FileUploadRemoveEvent } from "primereact/fileupload";

const renderChannelOption = (
    config: ChannelConfig,
    control: Control,
    errors?: FieldErrors,
    disabled?: boolean,
    multipleFilesUpload?: boolean,
    existingFiles?: ChannelDocument[],
    complaintId?: number,
    currentFiles: Array<File> = [],
    setValue?: UseFormSetValue<Record<string, never>>
) => {
    const fields: ReactNode[] = [];
    const {
        channel_type,
        label,
        content,
        file,
        ...fieldConfigs
    } = config;

    const onFileRemove = (e: FileUploadRemoveEvent) => {
        if (setValue) {
            const newFiles = currentFiles.filter((file: File) => file.name !== e.file.name);
            setValue('channel.files', newFiles);
        }
    };

    let fieldName: keyof typeof fieldConfigs;
    for (fieldName in fieldConfigs) {
        const fieldConfig = fieldConfigs[fieldName];
        if (fieldConfig?.display) {
            const fieldId = `channel.${fieldName}`;
            if (fieldConfig.options) {
                const fieldOptions = fieldConfig.options.map((option) => {
                    return {
                        label: option,
                        value: option
                    };
                });
                fields.push(
                    <BaseDropdown
                        className="mt-3"
                        key={fieldId}
                        name={fieldId}
                        control={control}
                        disabled={disabled}
                        label={fieldConfig.label ?? ''}
                        options={fieldOptions}
                        errorMessages={errors?.channel?.[fieldName]?.message}
                    />
                );
            } else {
                fields.push(
                    <BaseTextInput
                        className="mt-3"
                        key={fieldId}
                        name={fieldId}
                        control={control}
                        disabled={disabled}
                        label={fieldConfig.label ?? ''}
                        errorMessages={errors?.channel?.[fieldName]?.message}
                    />
                );
            }
        }
    }
    if (content?.display) {
        fields.push(
            <BaseTextEditor
                key="channel.content"
                className="mt-3"
                textareaName="channel.content"
                control={control}
                disabled={disabled}
                label={content.label ?? ''}
                errorMessages={errors?.channel?.content?.message}
            />
        );
    }
    if (file?.display) {
        fields.push(
            <AdvancedFileUpload
                key="channel.files"
                className="mt-3"
                name="channel.files"
                control={control}
                label={file.label ?? ''}
                multiple={multipleFilesUpload}
                existingFiles={existingFiles}
                downloadExistingFiles={!!existingFiles}
                complaintId={complaintId}
                disabled={disabled}
                onRemove={onFileRemove}
                errorMessages={errors?.channel?.files?.message ?? errors?.channel?.files}
            />
        );
    }
    return fields;
};

export type ComplaintChannelSelectProps = {
    control: Control
    required?: boolean
    errors?: FieldErrors
    disabled?: boolean
    complaintId?: number
    multipleFilesUpload?: boolean
    existingFiles?: ChannelDocument[],
    setValue?: UseFormSetValue<Record<string, never>>
};

const ComplaintChannelSelect: React.FC<ComplaintChannelSelectProps> = ({
    control,
    required,
    errors,
    disabled = false,
    multipleFilesUpload = false,
    existingFiles = [],
    complaintId,
    setValue
}: ComplaintChannelSelectProps) => {
    const { data: channelConfigs } = useGetChannelConfig();

    const availableChannelOptions = useMemo(() => {
        return channelConfigs?.map((channelConfigElement) => {
            return {
                label: channelConfigElement.label,
                value: channelConfigElement.channel_type
            };
        });
    }, [channelConfigs]);

    const channel = useWatch({
        control,
        name: 'channel'
    });

    const channelOptionFields = useMemo(() => {
        const channelConfig = channelConfigs?.find(config => config.channel_type === channel?.channel_type);
        if (channelConfig) {
            return renderChannelOption(
                channelConfig,
                control,
                errors,
                disabled,
                multipleFilesUpload,
                existingFiles,
                complaintId,
                channel.files,
                setValue
            );
        }
        return [];
    }, [channelConfigs, channel?.channel_type, channel?.files, control, errors]);

    return (
        <div>
            <BaseDropdown
                key="channel.channel_type"
                label="Channel Type"
                name="channel.channel_type"
                control={control}
                disabled={disabled}
                required={required}
                options={availableChannelOptions}
                errorMessages={errors?.channel?.channel_type?.message}
            />
            {
                channelOptionFields.map((field) => {
                    return field;
                })
            }
        </div>
    );
};

export default ComplaintChannelSelect;
