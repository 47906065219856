import { useMutation } from "@tanstack/react-query";
import { UpdateUserOperationRequest } from "@/stub";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";
import { usersApiClient } from "@/Service/Api/ApiClients/UsersApiClient";

export const useUpdateUser = () => useMutation({
    mutationFn: async (data: UpdateUserOperationRequest) => {
        try {
            await usersApiClient.updateUser(data);
        } catch (error) {
            await processValidationErrors(error);
            throw error;
        }
    }
});
