import React from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import { createGlobalStyle } from "styled-components";
import UserForm, { UserFormFormProps } from "@/components/Core/User/UserForm";
import { useGetOrganisation } from "@/Service/Api/ApiHooks/Organisation/useGetOrganisation";
import { useAuthStore } from "@/Stores/AuthStore";

export type NewUserModalProps = {
    dialogProps: DialogProps
    userFormProps: UserFormFormProps
};

const NewUserModalStyle = createGlobalStyle`
    .user-form {
        width: 100%;
    }
`;

const NewUserModal: React.FC<NewUserModalProps> = ({ dialogProps, userFormProps }: NewUserModalProps) => {
    const organisation_id = useAuthStore.getState().impersonatedOrgId ?? useAuthStore.getState().authUser?.organisation_id;
    const { data: organisation } = useGetOrganisation({ requestParams: { organisation_id: organisation_id as number } });
    return (
        <>
            <NewUserModalStyle/>
            <Dialog
                draggable={false}
                blockScroll
                header={`Create new user for ${organisation?.name} organization`}
                {...dialogProps}
            >
                <UserForm
                    {...userFormProps}
                />
            </Dialog>
        </>
    );
};

export default NewUserModal;
