import { Column, type ColumnBodyOptions } from 'primereact/column';
import React, { useMemo, useState } from 'react';
import { Card } from 'primereact/card';
import { ExcelIcon } from '@/helpers/svg-icons';
import { Tooltip } from 'primereact/tooltip';
import { useGetSummaryGeneral } from '@/Service/Api/ApiHooks/Reports/useGetSummaryGeneral';
import { useGetSummaryBreaches } from '@/Service/Api/ApiHooks/Reports/useGetSummaryBreaches';
import { useGetSummaryFos } from '@/Service/Api/ApiHooks/Reports/useGetSummaryFos';
import { percentBodyTemplate, poundBodyTemplate } from '../templates/DatatableColumnTemplates';
import { useOrgId } from "@/Hooks/useOrgId";
import { formatToApiDate } from "@/Util/formatToApiDate";
import {
    BreachesReportResource,
    FosReportResource,
    GetLettersRequest,
    ProductReportResource
} from "@/stub";
import { DataTable } from "primereact/datatable";
import AnglesSort from "@/components/Icons/AnglesSort";
import BaseCalendar from "@/components/Core/Form/BaseCalendar";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useFullComplaintsListExport } from "@/Service/Api/ApiHooks/Complaint/useFullComplaintsListExport";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { Button } from "primereact/button";
import { KPISummaryExportMessage } from "@/Messages/Toast/Reports/KPISummaryExportMessage";
import { addMonth } from "@formkit/tempo";
import { checkPermission } from "@/Util/permissionChecks";
import ComplaintsReportModal from "@/components/Modals/ComplaintsReportModal";
import { useAuthStore } from "@/Stores/AuthStore";


type TableColumnDefinition<T = undefined> = {
    label: string
    sortable?: boolean
    field: string
    sortField?: string
    headerTooltip?: string
    body?: React.ReactNode | ((data: T, options: ColumnBodyOptions) => React.ReactNode)
};

const today = new Date();
const sixMonthsAgo = addMonth(today, -6);

const generalColumns: TableColumnDefinition<ProductReportResource>[] = [
    {
        field: 'date',
        label: 'Date',
    },
    {
        field: 'received',
        label: 'Received',
    },
    {
        field: 'resolved',
        label: 'Closed - pending FRL',
    },
    {
        field: 'closed',
        label: 'Closed',
    },
    {
        field: 'avg_days_close',
        label: 'Average days close',
    },
    {
        field: 'closure_3d_percent',
        label: 'Closure 3d percent',
        body: (data) => percentBodyTemplate(data?.closure_3d_percent),
    },
    {
        field: 'closure_3d_8wk_percent',
        label: 'Closure 3d 8wk percent',
        body: (data) => percentBodyTemplate(data?.closure_3d_8wk_percent),
    },
    {
        field: 'closure_8wk_percent',
        label: 'Closure 8wk percent',
        body: (data) => percentBodyTemplate(data?.closure_8wk_percent),
    },
    {
        field: 'closure_not_upheld_percent',
        label: 'Closure not upheld percent',
        body: (data) => percentBodyTemplate(data?.closure_not_upheld_percent),
    },
    {
        field: 'closure_partial_percent',
        label: 'Closure partial percent',
        body: (data) => percentBodyTemplate(data?.closure_partial_percent),
    },
    {
        field: 'closure_uphold_percent',
        label: 'Closure uphold percent',
        body: (data) => percentBodyTemplate(data?.closure_uphold_percent),
    },
    {
        field: 'remediation_total',
        label: 'Remediation total',
        body: (data) => poundBodyTemplate(data?.remediation_total),
    }

];

const breachesColumns: TableColumnDefinition<BreachesReportResource>[] = [
    {
        field: 'date',
        label: 'Date',
    },
    {
        field: 'letter_ack_breach_vol',
        label: 'Letter ack breach vol',
    },
    {
        field: 'letter_ack_breach_percent',
        label: 'Letter ack breach percent',
        body: (data) => percentBodyTemplate(data?.letter_ack_breach_percent),
    },
    {
        field: 'letter_4wk_breach_vol',
        label: 'Letter 4wk breach vol',
    },
    {
        field: 'letter_4wk_breach_percent',
        label: 'Letter 4wk breach percent',
        body: (data) => percentBodyTemplate(data?.letter_4wk_breach_percent),
    },
    {
        field: 'letter_8wk_breach_vol',
        label: 'Letter 8wk breach vol',
    },
    {
        field: 'letter_8wk_breach_percent',
        label: 'Letter 8wk breach percent',
        body: (data) => percentBodyTemplate(data?.letter_8wk_breach_percent),
    }
];

const fosColumns: TableColumnDefinition<FosReportResource>[] = [
    {
        field: 'date',
        label: 'Date',
    },
    {
        field: 'adjudication_open',
        label: 'Adjudication open',
    },
    {
        field: 'final_decision_open',
        label: 'Final decision open',
    },
    {
        field: 'fos_breach_vol',
        label: 'FOS breach vol',
    },
    {
        field: 'final_fos_breach_vol',
        label: 'Final fos breach vol',
    },
    {
        field: 'other_remedial_breach_vol',
        label: 'Other remedial breach vol',
    }
];

/**
 * KPI Summary datatable
 */
const KpiSummaryDatatable = () => {
    const orgId = useOrgId();
    const [dateRangeFilter, setDateRangeFilter] = useState([sixMonthsAgo, today]);
    const { mutateAsync, isPending } = useFullComplaintsListExport();
    const authUser = useAuthStore((state) => state.authUser);
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const requestParams = useMemo<GetLettersRequest>(() => ({
        from: formatToApiDate(dateRangeFilter?.[0]),
        to: formatToApiDate(dateRangeFilter?.[1]),
        organisation_id: orgId
    }), [dateRangeFilter,orgId]);

    const handleGenerateExport = async () => {
        await mutateAsync({
            ExportKPISummaryReportRequest: requestParams
        }, {
            onSuccess: () => {
                addToastMessage(KPISummaryExportMessage);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const {
        data: generalTableData,
        isLoading: loadingGeneralTable
    } = useGetSummaryGeneral(requestParams);

    const {
        data: breachesTableData,
        isLoading: loadingBreachesTable
    } = useGetSummaryBreaches(requestParams);

    const {
        data: fosTableData,
        isLoading: loadingFosTable
    } = useGetSummaryFos(requestParams);

    return (
        <>
            <div className="relative">
                <div className="page-header flex justify-content-between">
                    <div>
                        <h3 className="page-title">KPI Summary</h3>
                        <span className="page-subtitle">Forseti Complaints Manager</span>
                    </div>
                </div>

                <div className="page-item filter-cards">
                    <Card>
                        <div className="flex align-items-center">
                            <BaseCalendar
                                name='date-range-filter'
                                value={dateRangeFilter}
                                selectionMode='range'
                                dateFormat='dd/mm/yy'
                                maxDate={today}
                                placeholder='Select date range'
                                onClearButtonClick={() => setDateRangeFilter([sixMonthsAgo, today])}
                                showButtonBar
                                selectOtherMonths
                                readOnlyInput
                                hideOnRangeSelection
                                onChange={(event) => {
                                    setDateRangeFilter(event.value);
                                }}
                            />

                            <div>
                                <Button id={'full-extract-report'} disabled={isPending} text className="fake-link ml-3" onClick={handleGenerateExport}>
                                    <ExcelIcon />
                                </Button>
                                <Tooltip
                                    target="#full-extract-report"
                                    content="Full Extract"
                                    position="top"
                                />
                            </div>

                            {checkPermission(authUser?.permissions, 'read:full_report') &&
                                <ComplaintsReportModal
                                    dateRangeFilter={dateRangeFilter}
                                />
                            }

                        </div>
                    </Card>
                </div>
            </div>

            <div className="general-table-wrap mb-5">
                <h4 className="m-0 mb-2 ml-4">General</h4>

                <DataTable
                    <ProductReportResource[]>
                    lazy
                    loading={loadingGeneralTable}
                    value={generalTableData}
                    selectionMode={'single'}
                    scrollable
                    dataKey='date'
                    className='reports-datatable auto-height-dt p-4 mb-5'
                    emptyMessage="No reports found."
                    rowsPerPageOptions={[10, 20, 50]}
                    paginator={false}
                    sortIcon={(options) => {
                        return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                    }}

                >
                    {generalColumns.map(
                        column => <Column
                            key={column.label}
                            field={column.field}
                            body={column.body}
                            header={column.label}
                            sortable={column.sortable}
                            sortField={column.sortField}
                            headerTooltip={column.headerTooltip}
                        />
                    )}
                </DataTable>

            </div>


            <div className="breaches-table-wrap mb-5">
                <h4 className="mb-2 ml-4">Breaches</h4>

                <DataTable
                    <BreachesReportResource[]>
                    lazy
                    loading={loadingBreachesTable}
                    value={breachesTableData}
                    selectionMode={'single'}
                    scrollable
                    dataKey='date'
                    className='reports-datatable auto-height-dt p-4 mb-5'
                    emptyMessage="No reports found."
                    paginator={false}
                    sortIcon={(options) => {
                        return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                    }}

                >
                    {breachesColumns.map(
                        column => <Column
                            key={column.label}
                            field={column.field}
                            body={column.body}
                            header={column.label}
                            sortable={column.sortable}
                            sortField={column.sortField}
                            headerTooltip={column.headerTooltip}
                        />
                    )}
                </DataTable>
            </div>

            <div className="fos-table-wrap mb-5">
                <h4 className="mb-2 ml-4">FOS</h4>
                <DataTable
                    <FosReportResource[]>
                    lazy
                    loading={loadingFosTable}
                    value={fosTableData}
                    selectionMode={'single'}
                    scrollable
                    dataKey='date'
                    className='reports-datatable auto-height-dt p-4 mb-5'
                    emptyMessage="No reports found."
                    rowsPerPageOptions={[10, 20, 50]}
                    paginator={false}
                    sortIcon={(options) => {
                        return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                    }}

                >
                    {fosColumns.map(
                        column => <Column
                            key={column.label}
                            field={column.field}
                            body={column.body}
                            header={column.label}
                            sortable={column.sortable}
                            sortField={column.sortField}
                            headerTooltip={column.headerTooltip}
                        />
                    )}
                </DataTable>
            </div>
        </>
    );
};

export default KpiSummaryDatatable;
