import { useMutation } from '@tanstack/react-query';
import { RootCauseFcaMappingRequest } from "@/stub";
import { rootCauseApiClient } from "@/Service/Api/ApiClients/RootCauseApiClient";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";

export const useUpdateRootCauseFcaMapping = () => {
    return useMutation({
        mutationFn: async (data: RootCauseFcaMappingRequest) => {
            try {
                return await rootCauseApiClient.rootCauseFcaMapping(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
