import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";

const DefaultGlobalToast = () => {
    const toastMessages = useToastMessagesStore((state) => state.toastMessages);
    const getToastMessages = useToastMessagesStore((state) => state.getToastMessages);

    const toastRef = useRef<Toast>(null);

    useEffect(() => {
        if (toastMessages.length > 0) {
            const messages = getToastMessages();
            messages.forEach((message) => {
                toastRef.current?.show(message);
            });
        }
    }, [getToastMessages, toastMessages]);

    return (
        <Toast ref={toastRef}/>
    );
};

export default DefaultGlobalToast;
