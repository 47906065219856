import React, { useCallback, useEffect, useMemo } from 'react';
import { Button } from 'primereact/button';
import { useGetComplaintInvestigation } from '@/Service/Api/ApiHooks/ComplaintInvestigation/useGetComplaintInvestigation';
import {
    type Complaint,
    type ComplaintEvidence,
    type ComplaintInvestigation,
    type ComplaintOutcome,
    type ComplaintPoints,
    DocumentableType, UpsertComplaintInvestigationPointsRequestPointsInner,
} from '@/stub';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Divider } from 'primereact/divider';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateComplaintPoints } from '@/Service/Api/ApiHooks/ComplaintPoints/useUpdateComplaintPoints';
import { useToastMessagesStore } from '@/Stores/ToastMessagesStore';
import {
    useUpdateComplaintInvestigation
} from '@/Service/Api/ApiHooks/ComplaintInvestigation/useUpdateComplaintInvestigation';
import { COMPLAINT_ALLOWED_EXTENSIONS, FILE_UPLOAD_MAX_SIZE } from '@/config/constants';
import {
    ComplaintInvestigationUpdatedMessage
} from '@/Messages/Toast/ComplaintInvestigation/ComplaintInvestigationUpdatedMessage';
import styled from 'styled-components';

import { useUpdateComplaintDocument } from "@/Service/Api/ApiHooks/ComplaintDocuments/useUpdateComplaintDocument";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { useQueryClient } from "@tanstack/react-query";
import ComplaintPointsForm from "@/components/Core/Investigation/ComplaintPointsForm";
import ComplaintEvidenceForm from "@/components/Core/Investigation/ComplaintEvidenceForm";
import ComplaintOutcomeForm from "@/components/Core/Investigation/ComplaintOutcomeForm";
import SummaryInputForm from "@/components/Core/Investigation/SummaryInputForm";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { formatToApiDate } from "@/Util/formatToApiDate";
import { useFormStateStore } from "@/Stores/FormStore";
import { isEmpty } from "@/Util/isEmptyObject";

const StyledWrap = styled.main`
    .input {
        width: inherit;
        .p-component{
            width: inherit;
        }
    }
    .multiselect-selected-label > p {
        margin: 0;
    }
`;

export type InvestigationFormProps = {
    complaint: Complaint
};

const PointSchema = z.object({
    id: z.union([z.number(), z.string()]).optional(),
    content: z.string()
}
);

const DocumentSchema = z.instanceof(File)
    .refine((file?: File) => !file || file.size <= FILE_UPLOAD_MAX_SIZE, `Max upload size is ${FILE_UPLOAD_MAX_SIZE / 1048576} MB`)
    .refine((file?: File) => !file || COMPLAINT_ALLOWED_EXTENSIONS.includes(file.type ?? ''), 'File format not supported');

const EvidenceSchema = z.object({
    id: z.number().optional(),
    type: z.string().optional(),
    date: z.date().optional(),
    summary: z.string().optional(),
    points: z.array(z.string()).optional(),
    files: z.array(DocumentSchema).optional()
}
).superRefine((data, ctx) => {
    if (!data.type) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Type is required',
            path: ['type']
        });
    }
    if (!data.date) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Date is required',
            path: ['date']
        });
    }
    if (!data.summary) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Summary is required',
            path: ['summary']
        });
    }
    if (!data.points?.length) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Related complaint points are required',
            path: ['points']
        });
    }
});

const OutcomeSchema = z.object({
    id: z.number().optional(),
    type: z.string().optional(),
    content: z.string().optional(),
    points: z.array(z.string()).optional()
}
).superRefine((data, ctx) => {
    if (!data.type) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Type is required',
            path: ['type']
        });
    }
    if (!data.content) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Content is required',
            path: ['content']
        });
    }
    if (!data.points?.length) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Related complaint points are required',
            path: ['points']
        });
    }
});

const InvestigationFormSchema = z.object({
    points: z.array(PointSchema).min(1, 'Points array must contain at least 1 element'),
    evidence: z.array(EvidenceSchema).optional(),
    outcomes: z.array(OutcomeSchema).optional(),
    investigation_summary: z.string().optional()
});

const Investigation: React.FC<InvestigationFormProps> = ({
    complaint
}: InvestigationFormProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const setDirty = useFormStateStore((state) => state.setDirty);
    const {
        data: investigationData
    } = useGetComplaintInvestigation({
        requestParams: { complaint_id: complaint?.id }
    });

    const {
        control,
        reset,
        handleSubmit,
        setValue,
        setError,
        watch,
        getValues,
        formState: { errors, dirtyFields }
    } = useForm<ComplaintInvestigation>({
        resolver: zodResolver(InvestigationFormSchema),
        defaultValues: useMemo(() => {
            return { ...investigationData, investigation_summary: investigationData?.investigation_summary ?? '' };
        }, [investigationData])
    });

    const updatePointsMutation = useUpdateComplaintPoints({ setError });
    const updateInvestigationMutation = useUpdateComplaintInvestigation({ setError });
    const updateComplaintDocumentMutation = useUpdateComplaintDocument({ setError });


    const pointsRaised: ComplaintPoints[] | undefined = useWatch({
        control,
        name: 'points'
    });

    const evidenceConsidered: ComplaintEvidence[] | undefined = useWatch({
        control,
        name: 'evidence'
    });

    const outcomeConsiderations: ComplaintOutcome[] | undefined = useWatch({
        control,
        name: 'outcomes'
    });

    const {
        fields: pointsFields,
        append: pointsAppend,
        remove: pointsRemove
    } = useFieldArray({
        control,
        name: 'points'
    });

    const {
        fields: evidenceFields,
        append: evidenceAppend,
        remove: evidenceRemove
    } = useFieldArray({
        control,
        name: 'evidence'
    });

    const {
        fields: outcomesFields,
        append: outcomesAppend,
        remove: outcomesRemove
    } = useFieldArray({
        control,
        name: 'outcomes'
    });

    const pointsOptions = useMemo(() => {
        const pointsOptions: ComplaintPoints[] = [];
        pointsRaised?.forEach(point => {
            if (point.content) {
                pointsOptions.push({
                    id: point.id,
                    content: point.content
                });
            }
        });
        return pointsOptions;
    }, [pointsRaised]);

    const validate = (data: ComplaintInvestigation) => {
        return InvestigationFormSchema.safeParse(data).success;
    };

    const processPointsToSend = (points: ComplaintPoints[]): UpsertComplaintInvestigationPointsRequestPointsInner[] => {
        const processedPoints: UpsertComplaintInvestigationPointsRequestPointsInner[] = [];
        points?.forEach(point => {
            processedPoints.push({ id: typeof point.id !== 'string' ? point.id : undefined , content: point.content ?? '' });
        });
        return processedPoints;
    };

    const processEvidenceOrOutcomeToSend = (element: ComplaintEvidence[] | ComplaintOutcome[], points: ComplaintPoints[]): ComplaintEvidence[] => {
        element?.forEach((elementItem: ComplaintEvidence | ComplaintOutcome, index) => {
            const newPoints: number[] = [];
            elementItem.points?.forEach(elementPoint => {
                const matchPoint = points.find(point => point.content === elementPoint);
                if (matchPoint?.id) {
                    newPoints.push(matchPoint.id);
                }
            });
            elementItem.points = newPoints;
            elementItem.date = formatToApiDate(elementItem.date);
        });
        return element;
    };

    const invalidateQueries = () => {
        queryClient.invalidateQueries({
            queryKey: QueryKeys.investigation.detail({ complaint_id: complaint?.id }).queryKey
        });
        queryClient.invalidateQueries({
            queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
        });
    };

    const onInvestigationSubmit = async (data: ComplaintInvestigation) => {
        if (validate(data)) {
            await updatePointsMutation.mutateAsync({
                complaint_id: complaint.id,
                UpsertComplaintInvestigationPointsRequest: {
                    points: processPointsToSend(data?.points ?? [])
                }
            }, {
                onSuccess: async (newPoints: ComplaintPoints[]) => {
                    setValue('points', newPoints);
                    const newEvidence = processEvidenceOrOutcomeToSend(data?.evidence ?? [], newPoints);
                    const newOutcome = processEvidenceOrOutcomeToSend(data?.outcomes ?? [], newPoints);

                    await updateInvestigationMutation.mutateAsync({
                        complaint_id: complaint.id,
                        UpsertComplaintInvestigationRequest: {
                            evidence: newEvidence,
                            outcomes: newOutcome,
                            investigation_summary: data.investigation_summary
                        }
                    }, {
                        onSuccess: async (investigationResponse) => {
                            for (const [evidenceIndex, evidence] of data.evidence?.entries() ?? []) {
                                if (!!evidence.files?.length) {
                                    await updateComplaintDocumentMutation.mutateAsync({
                                        complaint_id: complaint.id,
                                        file: evidence.files[0],
                                        documentable_type: DocumentableType.Evidence,
                                        documentable_id: investigationResponse.evidence[evidenceIndex].id
                                    });
                                }
                            }
                            invalidateQueries();
                            addToastMessage(ComplaintInvestigationUpdatedMessage);
                        },
                        onError: error => {
                            addToastMessage(CustomErrorMessage(error));
                        }
                    });
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            });
        }
    };
    const addPoint = useCallback(() => {
        pointsAppend({
            id: crypto.randomUUID(),
            content:  ''
        });
    }, [pointsFields]);

    const removePoint = useCallback((point: ComplaintPoints, index: number) => {
        evidenceConsidered?.forEach((evidence, evidenceIndex) => {
            const matchPoint = evidence.points?.find(evidencePoint => evidencePoint === point.content);
            if (matchPoint) {
                setValue(`evidence[${evidenceIndex}].points`, evidence?.points?.filter(point => point !== matchPoint));
            }
        });

        outcomeConsiderations?.forEach((outcome, outcomeIndex) => {
            const matchPoint = outcome.points?.find(outcomePoint => outcomePoint === point.content);
            if (matchPoint) {
                setValue(`outcomes[${outcomeIndex}].points`, outcome?.points?.filter(point => point !== matchPoint));
            }
        });
        getValues('evidence')?.forEach((evidence, evidenceIndex) => {
            const pointIndex = evidence.points?.findIndex((evidencePoint: string) => evidencePoint === point.content);
            if (pointIndex !== -1) {
                setValue(`evidence[${evidenceIndex}].points`, evidence.points.filter((_, i) => i !== pointIndex));
            }
        });
        getValues('outcomes')?.forEach((outcome, outcomeIndex) => {
            const pointIndex = outcome.points?.findIndex((outcomePoint: string) => outcomePoint === point.content);
            if (pointIndex !== -1) {
                setValue(`outcomes[${outcomeIndex}].points`, outcome.points.filter((_, i) => i !== pointIndex));
            }
        });
        pointsRemove(index);
    }, [evidenceConsidered, outcomeConsiderations, pointsRemove]);

    const addEvidence = useCallback(() => {
        evidenceAppend({
            id: undefined,
            points: [],
            type: undefined,
            summary: undefined,
            date: undefined,
            document: undefined
        });
    }, [evidenceFields]);

    const removeEvidence = useCallback((index: number) => {
        evidenceRemove(index);
    }, [evidenceRemove]);

    const addOutcome = useCallback(() => {
        outcomesAppend({
            id: undefined,
            points: [],
            type: undefined,
            content: undefined,
            date: undefined,
            remediation: undefined
        });
    }, [outcomesFields]);

    const removeOutcome = useCallback((index: number) => {
        outcomesRemove(index);
    }, [outcomesRemove]);

    useEffect(() => {
        reset({
            ...investigationData,
            investigation_summary: investigationData?.investigation_summary ?? '',
            evidence: investigationData?.evidence?.map(evidence => ({
                ...evidence,
                points: evidence.points?.map(point => (point.content))
            })),
            outcomes: investigationData?.outcomes?.map(outcome => ({
                ...outcome,
                points: outcome.points?.map(point => (point.content))
            }))
        });
    }, [investigationData]);


    const isDirty = !isEmpty(dirtyFields);
    useEffect(() => {
        setDirty(isDirty);
    }, [isDirty]);

    return (
        <StyledWrap>
            <form onSubmit={handleSubmit(onInvestigationSubmit)}>
                <ComplaintPointsForm
                    complaint={complaint}
                    control={control}
                    addPoint={addPoint}
                    removePoint={removePoint}
                    getValues={getValues}
                    setValue={setValue}
                    errorMessages={errors}
                />
                <Divider/>
                <ComplaintEvidenceForm
                    complaint={complaint}
                    availablePoints={pointsOptions}
                    evidenceFields={evidenceFields}
                    addEvidence={addEvidence}
                    control={control}
                    removeEvidence={removeEvidence}
                    errorMessages={errors}
                    setValue={setValue}
                />
                <Divider/>
                <ComplaintOutcomeForm
                    complaint={complaint}
                    outcomeFields={outcomesFields}
                    addOutcome={addOutcome}
                    removeOutcome={removeOutcome}
                    availablePoints={pointsOptions}
                    control={control}
                    errorMessages={errors}
                />
                <Divider/>
                <SummaryInputForm
                    complaint={complaint}
                    control={control}
                    errorMessages={errors}
                />
                <div className="flex">
                    <Button
                        type="submit"
                        label='Submit'
                        className="ml-auto"
                        icon='pi pi-check-circle'
                        iconPos="right"
                        disabled={isReadonlyComplaint(complaint)}
                        loading={updateInvestigationMutation.isPending || updatePointsMutation.isPending}
                    />
                </div>
            </form>
        </StyledWrap>
    );
};

export default Investigation;
