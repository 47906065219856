import React, { useMemo, useState } from "react";
import { GetComplaintsRequest, OAFosState } from "@/stub";
import { authUserCan } from "@/Util/permissionChecks";
import ContentHeader from "@/components/Blocks/ContentHeader";
import styled from "styled-components";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { useDebounce } from "react-use";
import { useAuthUser } from "@/Hooks/useAuthUser";
import ComplaintsFosDatatable from "@/components/Datatables/ComplaintsFosDatatable";

const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }
    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;

export type FosOutboxPageProps = {
    className?: string
};

const FosOutboxPage: React.FC<FosOutboxPageProps> = ({
    className
}: FosOutboxPageProps) => {
    const authUser = useAuthUser();

    const [searchInput, setSearchInput] = useState<string>('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');

    useDebounce(
        () => {
            setDebouncedSearchInput(searchInput);
        },
        600,
        [searchInput]
    );

    const fosOutboxRequestParams = useMemo<GetComplaintsRequest>(() => {
        const unclosedStates: OAFosState[] = [
            OAFosState.AdjudicationClosed,
            OAFosState.FinalDecisionClosed
        ];
        return {
            assigned_to_id: !authUserCan('read:org_complaint_detail') ? authUser?.id : undefined,
            is_fos: true,
            fos_states: unclosedStates,
            search: debouncedSearchInput.length > 0 ? debouncedSearchInput :  undefined
        };
    }, [authUser, debouncedSearchInput]);

    return (
        <div>
            <StyledWrap className={className}>
                <ContentHeader
                    title="FOS Outbox"
                    description="Forseti Complaints Manager"
                    rightSectionTemplate={
                        <BaseTextInput
                            placeholder="Search"
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                        />
                    }
                />
                <div className="content-container">
                    <ComplaintsFosDatatable
                        frozenData
                        requestParams={fosOutboxRequestParams}
                    />
                </div>
            </StyledWrap>
        </div>
    );
};

export default FosOutboxPage;
