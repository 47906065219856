import { useEffect } from 'react';
import '../styles/datatables.scss';
import { setDocumentTitle } from '@/helpers/general';
import KpiSummaryDatatable from '../components/Datatables/KpiSummaryDatatable';
import { withAuthenticationRequired } from '@auth0/auth0-react';

/**
 * KPI Summary page
 */
const KpiSummaryPage = (): JSX.Element => {
    useEffect(() => {
        setDocumentTitle('KPI Summary');
    }, []);

    return (
        <div className="page-content">
            <KpiSummaryDatatable />
        </div>
    );
};

export default withAuthenticationRequired(KpiSummaryPage);
