import React, { useId } from "react";
import { InputText, InputTextProps } from "primereact/inputtext";
import { Controller } from "react-hook-form";
import { clsx } from "clsx";
import { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import ErrorsBlock from "@/components/Core/Form/ErrorsBlock";
import { fieldsPlaceholders } from "@/config/forms";

export type BaseTextInputPathThrough = {
    label?: {
        className?: string | undefined
    }
    inputText?: {
        className?: string | undefined
    }
};

export type BaseTextInputProps = InputTextProps & BaseFormInputProps & {
    pt?: BaseTextInputPathThrough
};

const BaseTextInput: React.FC<BaseTextInputProps> = ({
    control,
    label,
    errorMessages,
    className,
    pt,
    ...props
}: BaseTextInputProps) => {
    const localId = useId();

    return (
        <div className={className}>
            {
                label
                && <label
                    className={clsx("block mb-2", pt?.label?.className)}
                    htmlFor={localId}
                >{label}{props.required ? '*' : ''}</label>
            }
            {
                control && props.name
                && <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <InputText
                            className={pt?.inputText?.className}
                            id={localId}
                            name={field.name}
                            invalid={fieldState.invalid}
                            value={field.value}
                            disabled={field.disabled}
                            autoComplete={props.autoComplete ?? 'off'}
                            aria-autocomplete={props['aria-autocomplete'] ?? 'none'}
                            placeholder={props.placeholder ?? fieldsPlaceholders.text}
                            onChange={(e) => {
                                field.onChange(e.target.value);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                            {...props}
                        />
                    )}
                />
            }
            {
                !control
                && <InputText
                    className={pt?.inputText?.className}
                    id={localId}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseTextInput;
