import { useMutation } from "@tanstack/react-query";
import { RootCauseToggleStatusRequest } from "@/stub";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";
import { rootCauseApiClient } from "@/Service/Api/ApiClients/RootCauseApiClient";

export const useToggleRootCauseStatus = () => {
    return useMutation({
        mutationFn: async (data: RootCauseToggleStatusRequest) => {
            try {
                return await rootCauseApiClient.rootCauseToggleStatus(data);
            } catch (error) {
                await processValidationErrors(error);
                throw error;
            }
        }
    });
};
