import { useSearchParams } from "react-router-dom";


export const RootCauseFilterType = {
    Status: 'states'
} as const;

export type RootCauseFilterType = typeof RootCauseFilterType[keyof typeof RootCauseFilterType];


export default function useGetRootCauseFiltersQuery() {
    const [searchParams] = useSearchParams();

    const statusQuery = searchParams.get(RootCauseFilterType.Status) ?? undefined;

    return {
        statusQuery
    };
}
