import React, { useMemo } from 'react';
import { RootCause, RootCauseStatus } from '@/stub';
import { useGetRootCauses } from '@/Service/Api/ApiHooks/RootCause/useGetRootCauses';
import { OptionsTree } from "@/Model/OptionsTree";
import { useWatch } from "react-hook-form";
import { findTreeNode } from "@/Util/findTreeNode";
import BaseRootCauseSelect, { BaseRootCauseSelectProps } from "@/components/Core/Form/Selector/BaseRootCauseSelect";

export type ComplaintRootCauseSelect = BaseRootCauseSelectProps;

const buildRootCauseTree = (
    rootCauses: RootCause[],
    filterInactive = true
): OptionsTree<RootCause>[] => {
    if (rootCauses?.length > 0) {
        let initialRootCauses = rootCauses;
        if (filterInactive) {
            initialRootCauses = rootCauses.filter(rootCause => rootCause.status !== RootCauseStatus.Inactive);
        }
        return initialRootCauses.map((rootCause) => {
            return new OptionsTree<RootCause>(
                rootCause.id,
                rootCause,
                rootCause.label,
                rootCause.parent_names,
                rootCause.selectable,
                buildRootCauseTree(rootCause.children ?? [], filterInactive)
            );
        });
    }
    return [];
};

const ComplaintRootCauseSelect: React.FC<ComplaintRootCauseSelect> = (props) => {

    const {
        data: rootCauses
    } = useGetRootCauses({
        select: (rootCauses: RootCause[]) => rootCauses?.[0]?.children ?? []
    });

    const rootCauseOptions = useMemo(() => {
        if (rootCauses) {
            return buildRootCauseTree(rootCauses);
        }
        return [];
    }, [rootCauses]);

    const controlledValue = useWatch({
        control: props.control,
        name: props.name ?? 'undefined',
    });

    const selectedValue = useMemo(() => {
        if (props.control) {
            return controlledValue;
        }
        return props.value;
    }, [controlledValue, props.control, props.value]);


    const rootCausePath = useMemo(() => {
        if (!selectedValue || !rootCauses) {
            return undefined;
        }
        const activeRootCause = findTreeNode(rootCauses, selectedValue);
        if (activeRootCause) {
            return `${activeRootCause.parent_names} > ${activeRootCause.label}`;
        }
    }, [rootCauses, selectedValue]);

    return (
        <div className='flex flex-row gap-2'>
            <BaseRootCauseSelect
                {...props}
                filter
                options={rootCauseOptions}
                queryEnabled={!!rootCauseOptions}
            />
            {
                rootCausePath && <span className='col-12 mt-auto pb-2'>{rootCausePath}</span>
            }
        </div>
    );
};

export default ComplaintRootCauseSelect;
