import { useQuery } from '@tanstack/react-query';
import { GetRootCausesLatestVersionRequest } from '@/stub';
import { rootCauseApiClient } from '@/Service/Api/ApiClients/RootCauseApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetRootCausesConfigVersionParams = {
    requestParams?: GetRootCausesLatestVersionRequest
};

export const useGetRootCausesConfigVersion = ({ requestParams }: useGetRootCausesConfigVersionParams) => useQuery({
    ...QueryKeys.rootCauses.configVersion(requestParams),
    queryFn: async () => await rootCauseApiClient.getRootCausesLatestVersion(requestParams),
});
