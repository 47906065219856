/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressSuggestion
 */
export interface AddressSuggestion {
    /**
     * 
     * @type {string}
     * @memberof AddressSuggestion
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressSuggestion
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressSuggestion
     */
    type?: AddressSuggestionTypeEnum;
}


/**
 * @export
 */
export const AddressSuggestionTypeEnum = {
    Address: 'Address',
    Locality: 'Locality',
    Street: 'Street',
    Postcode: 'Postcode'
} as const;
export type AddressSuggestionTypeEnum = typeof AddressSuggestionTypeEnum[keyof typeof AddressSuggestionTypeEnum];


/**
 * Check if a given object implements the AddressSuggestion interface.
 */
export function instanceOfAddressSuggestion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddressSuggestionFromJSON(json: any): AddressSuggestion {
    return AddressSuggestionFromJSONTyped(json, false);
}

export function AddressSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function AddressSuggestionToJSON(value?: AddressSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'type': value.type,
    };
}

