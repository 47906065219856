import React from 'react';
import { type SortOrder } from 'primereact/datatable';
import { clsx } from 'clsx';

export type AnglesSortProps = React.HTMLAttributes<HTMLDivElement> & {
    sortOrder?: SortOrder
    sorted?: boolean
};

const AnglesSort: React.FC<AnglesSortProps> = (
    {
        sortOrder,
        sorted,
        ...props
    }: AnglesSortProps) => {
    return (
        <div {...props} className="angles-sort flex flex-column">
            <i className={clsx('angles-sort__icon angles-sort__icon-up pi pi-angle-up', { active: sortOrder === 1 })}></i>
            <i className={clsx('angles-sort__icon angles-sort__icon-down pi pi-angle-down', { active: sortOrder === -1 })}></i>
        </div>
    );
};

export default AnglesSort;
