import { useGetRootCauses } from "@/Service/Api/ApiHooks/RootCause/useGetRootCauses";
import {
    GetRootCauseInTreeStructureRequest,
    GetRootCauseInTreeStructureStatusEnum,
    RootCause,
    RootCauseFcaMappingRequest,
    RootCauseStatus,
    RootCauseToggleStatusRequest,
} from "@/stub";
import React, { useMemo, useState } from "react";
import { OptionsTree } from "@/Model/OptionsTree";
import { Column, type ColumnBodyOptions } from "primereact/column";
import { Button } from "primereact/button";
import styled from "styled-components";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { RootCauseInfoInitData } from "@/components/Core/RootCause/RootCauseForm";
import { Tag, TagProps } from "primereact/tag";
import FcaRootCauseMappingModal from "@/components/Modals/FcaRootCauseMappingModal";
import { FcaRootCauseMappingFormData } from "@/components/Core/RootCause/FcaRootCauseMappingForm";
import { useUpdateRootCauseFcaMapping } from "@/Service/Api/ApiHooks/RootCause/useUpdateRootCauseFcaMapping";
import { RootCauseFcaMappingUpdatedMessage } from "@/Messages/Toast/RootCauses/RootFcaMappingUpdatedMessage";
import { TreeTable } from "primereact/treetable";
import { useToggleRootCauseStatus } from "@/Service/Api/ApiHooks/RootCause/useToggleRootCauseStatus";
import { RootCauseUpdatedMessage } from "@/Messages/Toast/RootCauses/RootCauseUpdatedMessage";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import RootCauseFiltersList from "@/components/Filters/RootCause/RootCauseFiltersList";
import useGetRootCauseFiltersQuery from "@/Hooks/useGetRootCauseFiltersQuery";

type RootCauseTableData = {
    key: number;
    data: RootCause;
    label: string;
    parent_names: string;
    selectable: boolean;
    children: RootCause[];
    code: string | null;
};

type TableColumnDefinition = {
    header: string
    expandable?: boolean
    field?: string
    sortField?: string
    body?: React.ReactNode | ((data: RootCauseTableData, options: ColumnBodyOptions) => React.ReactNode)
};

const StyledWrap = styled.main`
    .content-container {
        //padding: 0 1rem;
    }

    .page-actions {
        margin-bottom: .5rem;
        padding: .4rem;
        background: #F8F9FA;
        border-radius: 8px;
    }

    .action-button {
        max-width: 5.5rem;
        max-height: 3rem;
        font-size: 0.8rem;
    }
`;

const buildRootCauseTree = (
    rootCauses: RootCause[],
    filterInactive = true
): OptionsTree<RootCause>[] => {
    if (rootCauses?.length > 0) {
        return rootCauses.map((rootCause) => {
            return new OptionsTree<RootCause>(
                rootCause.id,
                rootCause,
                rootCause.label,
                rootCause.parent_names,
                rootCause.selectable,
                buildRootCauseTree(rootCause.children ?? [], filterInactive)
            );
        });
    }
    return [];
};

type RootCauseDatatableProps = {
    onAddChild: (data: Partial<RootCauseInfoInitData | RootCause>) => void
    filters: GetRootCauseInTreeStructureRequest & { globalFilter?: string }
};

const RootCauseDatatable = ({ filters, onAddChild }: RootCauseDatatableProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [fcaRootCauseData, setFcaRootCauseData] = useState<Partial<FcaRootCauseMappingFormData> | null>(null);
    const { mutate: toggleRootCauseStatus, isPending: isToggleRootCauseStatusPending } = useToggleRootCauseStatus();
    const { mutate: updateFcaMapping, isPending: isFcaMappingPending } = useUpdateRootCauseFcaMapping();
    const { statusQuery } = useGetRootCauseFiltersQuery();

    const getRootCausesRequest: GetRootCauseInTreeStructureRequest = useMemo(() => {
        return {
            status: statusQuery ? statusQuery as GetRootCauseInTreeStructureStatusEnum : undefined,
            selectable_lvl: 1
        };
    }, [statusQuery]);

    const {
        data: rootCauses,
        isLoading: isGetRootCausesLoading,
    } = useGetRootCauses({
        requestParams: getRootCausesRequest,
        select: (rootCauses: RootCause[]) => rootCauses?.[0]?.children ?? [],
        enabled: true
    });

    const rootCauseOptions = useMemo(() => {
        if (rootCauses) {
            return buildRootCauseTree(rootCauses);
        }
        return [];
    }, [rootCauses]);

    const updateFcaRootCauseMapping = (data: FcaRootCauseMappingFormData, persist: boolean) => {
        const payload: RootCauseFcaMappingRequest = {
            root_cause_id: fcaRootCauseData?.root_cause_id as number,
            UpdateRootCauseFcaMappingRequest: {
                fca_root_cause_id: data.fca_root_cause_id,
                persist_against_descendants: persist
            }
        };
        updateFcaMapping(payload, {
            onSuccess: () => {
                addToastMessage(RootCauseFcaMappingUpdatedMessage);
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.rootCauses.list({ selectable_lvl: 1 }).queryKey
                });
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.activityLog._def
                });
                setFcaRootCauseData(null);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const handleUpdateFcaMapping = async (data: FcaRootCauseMappingFormData) => {
        confirmDialog({
            header: 'Confirmation needed!',
            message: () => "Do you want to apply this action to all descendants?",
            rejectLabel: "No, apply only to this node",
            accept: () => {
                updateFcaRootCauseMapping(data, true);
            },
            reject: () => {
                updateFcaRootCauseMapping(data, false);
            }
        });
    };

    const updateRootCauseStatus = (rootCauseId: number, persist: boolean) => {
        const payload: RootCauseToggleStatusRequest = {
            root_cause_id: rootCauseId,
            ToggleRootCauseStatusRequest: {
                persist_against_descendants: persist
            }
        };
        toggleRootCauseStatus(payload, {
            onSuccess: () => {
                addToastMessage(RootCauseUpdatedMessage);
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.rootCauses.list({ selectable_lvl: 1 }).queryKey
                });
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.activityLog._def
                });
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const handleToggleStatus = (rootCauseId: number) => {
        confirmDialog({
            header: 'Confirmation needed!',
            message: () => "Do you want to apply this action to all descendants?",
            rejectLabel: "No, apply only to this node",
            accept: () => {
                updateRootCauseStatus(rootCauseId, true);
            },
            reject: () => {
                updateRootCauseStatus(rootCauseId, false);
            }
        });
    };

    const actionBodyTemplate = (rootCause: RootCause) => {
        return (
            <div className={'flex gap-1'}>
                <Button
                    label={'Add Child'}
                    className={'action-button'}
                    loading={isFcaMappingPending || isToggleRootCauseStatusPending}
                    onClick={() => onAddChild({
                        parent_id: rootCause.id,
                        lvl: rootCause.lvl + 1
                    })}/>
                <Button
                    className={'action-button'}
                    severity={rootCause.status === RootCauseStatus.Active ? 'danger' : 'success'}
                    label={rootCause.status === RootCauseStatus.Active ? 'Inactivate' : 'Activate'}
                    loading={isFcaMappingPending || isToggleRootCauseStatusPending}
                    onClick={() => {
                        handleToggleStatus(rootCause.id as number);
                    }}/>

                {rootCause.lvl >= 2 ?
                    <Button
                        className={'action-button'}
                        severity={'secondary'}
                        label={'FCA'}
                        loading={isFcaMappingPending || isToggleRootCauseStatusPending}
                        onClick={() => setFcaRootCauseData({
                            root_cause_id: rootCause.id as number,
                            fca_root_cause_id: rootCause.fca_root_cause?.id as number,
                            label: rootCause.label
                        })}/> : null}
            </div>
        );
    };

    const getLabelTag = (rootCause: RootCause): TagProps => {
        switch (rootCause.status) {
            case RootCauseStatus.Active:
                return {
                    severity: 'success',
                    value: 'Active'
                };
            case RootCauseStatus.Inactive:
                return {
                    severity: 'danger',
                    value: 'Inactive'
                };
        }
    };

    const labelBodyTemplate = (rootCause: RootCause) => {
        const tagProps = getLabelTag(rootCause);
        return <>{rootCause.label} <Tag {...tagProps}/></>;
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            header: 'Label',
            field: 'label',
            expandable: true,
            body: cause => labelBodyTemplate(cause.data),
        },
        {
            header: 'Parent Names',
            field: 'parent_names',
        },
        {
            header: 'FCA',
            body: cause => cause.data.fca_root_cause?.label
        },
        {
            header: 'Actions',
            body: cause => actionBodyTemplate(cause.data),
        },
    ];

    return (
        <div>
            <StyledWrap>
                <div className={'content-container'}>
                    <div className={'page-actions'}>
                        <RootCauseFiltersList className="flex gap-2 flex-wrap justify-content-start"/>
                    </div>
                    <TreeTable
                        globalFilter={filters.globalFilter}
                        value={rootCauseOptions}
                        rowHover
                        columnResizeMode={'expand'}
                        filterMode={'strict'}
                        loading={isGetRootCausesLoading}
                    >
                        {tableColumns.map((column, key) =>
                            <Column
                                key={`column-${key}`}
                                field={column.field}
                                header={column.header}
                                body={column.body}
                                expander={column.expandable}
                            />)}
                    </TreeTable>
                </div>
            </StyledWrap>

            <FcaRootCauseMappingModal
                dialogProps={{
                    header: 'FCA Mapping' + (fcaRootCauseData ? (` for ${fcaRootCauseData?.label}`) : ''),
                    visible: !!fcaRootCauseData,
                    onHide() {
                        setFcaRootCauseData(null);
                    }
                }}
                fcaRootCauseMappingFormProps={{
                    initFormData: fcaRootCauseData as FcaRootCauseMappingFormData,
                    onSubmit: handleUpdateFcaMapping,
                    onSubmitButtonLabel: 'Update',
                    isProcessing: isFcaMappingPending
                }}
            />
            <ConfirmDialog/>
        </div>
    );
};

export default RootCauseDatatable;
