import React from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import { createGlobalStyle } from "styled-components";
import { FileUpload, FileUploadProps } from "primereact/fileupload";
import { clsx } from "clsx";
import { useUploadRootCauseConfig } from "@/Service/Api/ApiHooks/RootCause/useUploadRootCauseConfig";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { RootCauseConfigUploadedMessage } from "@/Messages/Toast/RootCauses/RootCauseConfigUploadedMessage";
import ErrorsBlock from "@/components/Core/Form/ErrorsBlock";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";

export type UploadRootCauseConfigModalProps = {
    dialogProps: DialogProps,
    fileUploadProps?: FileUploadProps,
};

const UploadRootCauseConfigModalStyle = createGlobalStyle`
    .upload-root-cause-config-modal {
        width: 50%;
    }
`;

const UploadRootCauseConfigModal: React.FC<UploadRootCauseConfigModalProps> = ({
    dialogProps,
    fileUploadProps
}: UploadRootCauseConfigModalProps) => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const fileUploadRef = React.useRef<FileUpload>(null);
    const { mutateAsync: uploadFile, error: uploadFileError } = useUploadRootCauseConfig();

    const uploadHandler = async (event: MouseEvent) => {
        confirmPopup({
            target: event.target as HTMLElement,
            message: 'Please confirm to proceed moving forward. This action will be processed in background',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: async () => {
                await uploadFile({
                    file: fileUploadRef.current?.getFiles()[0]
                }, {
                    onSuccess: () => {
                        addToastMessage(RootCauseConfigUploadedMessage);
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.rootCauses._def
                        });
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.activityLog._def
                        });
                        dialogProps.onHide();
                    },
                    onError: error => {
                        addToastMessage(CustomErrorMessage(error));
                    }
                });
            },
            reject: () => {

            }
        });


    };

    return (
        <>
            <UploadRootCauseConfigModalStyle/>
            <Dialog
                {...dialogProps}
                className={clsx(dialogProps?.className, 'upload-root-cause-config-modal')}
                header={'Upload Root Causes Config'}
                draggable={false}
                blockScroll
            >
                <div className={'w-full'}>
                    <FileUpload
                        ref={fileUploadRef}
                        mode={fileUploadProps?.mode ?? 'advanced'}
                        multiple={fileUploadProps?.multiple ?? false}
                        cancelOptions={{ style: { display: 'none' } }}
                        accept={fileUploadProps?.accept ?? '.json'}
                        emptyTemplate={fileUploadProps?.emptyTemplate ?? 'Drag and drop your json file here'}
                        customUpload={fileUploadProps?.customUpload ?? true}
                        pt={{
                            uploadButton: { root: { onClick: uploadHandler } }
                        }}
                    />
                    <ConfirmPopup/>
                    <ErrorsBlock
                        className="block mt-2 p-error"
                        errorMessages={uploadFileError?.message}
                    />
                </div>
            </Dialog>
        </>
    );
};

export default UploadRootCauseConfigModal;
