/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreUserRequest
 */
export interface StoreUserRequest {
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    middlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    display_name?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    role: StoreUserRequestRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreUserRequest
     */
    additional_roles?: StoreUserRequestAdditionalRolesEnum;
    /**
     * 
     * @type {number}
     * @memberof StoreUserRequest
     */
    organisation_id?: number;
}


/**
 * @export
 */
export const StoreUserRequestRoleEnum = {
    Inactive: 'inactive',
    ServiceAccount: 'service_account',
    ComplaintLogger: 'complaint_logger',
    ComplaintHandler: 'complaint_handler',
    TeamLeader: 'team_leader',
    TeamFinance: 'team_finance',
    SystemAdmin: 'system_admin'
} as const;
export type StoreUserRequestRoleEnum = typeof StoreUserRequestRoleEnum[keyof typeof StoreUserRequestRoleEnum];

/**
 * @export
 */
export const StoreUserRequestAdditionalRolesEnum = {
    LetterDateHandler: 'forseti_letter_date_handler',
    FosHandler: 'forseti_fos_handler'
} as const;
export type StoreUserRequestAdditionalRolesEnum = typeof StoreUserRequestAdditionalRolesEnum[keyof typeof StoreUserRequestAdditionalRolesEnum];


/**
 * Check if a given object implements the StoreUserRequest interface.
 */
export function instanceOfStoreUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstname" in value;
    isInstance = isInstance && "surname" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function StoreUserRequestFromJSON(json: any): StoreUserRequest {
    return StoreUserRequestFromJSONTyped(json, false);
}

export function StoreUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstname': json['firstname'],
        'middlename': !exists(json, 'middlename') ? undefined : json['middlename'],
        'surname': json['surname'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'email': json['email'],
        'password': json['password'],
        'role': json['role'],
        'additional_roles': !exists(json, 'additional_roles') ? undefined : json['additional_roles'],
        'organisation_id': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
    };
}

export function StoreUserRequestToJSON(value?: StoreUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstname': value.firstname,
        'middlename': value.middlename,
        'surname': value.surname,
        'display_name': value.display_name,
        'email': value.email,
        'password': value.password,
        'role': value.role,
        'additional_roles': value.additional_roles,
        'organisation_id': value.organisation_id,
    };
}

