import React, { useId } from 'react';
import { Controller } from 'react-hook-form';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';
import { MultiSelect, type MultiSelectProps } from 'primereact/multiselect';
import { fieldsPlaceholders } from "@/config/forms";

export type BaseMultiselectPassThrough = {
    wrapper?: {
        className?: string | undefined
    }
};
export type BaseMultiselectProps = MultiSelectProps & BaseFormInputProps & {
    pt?: BaseMultiselectPassThrough
};

const BaseMultiselect: React.FC<BaseMultiselectProps> = ({
    control,
    label,
    errorMessages,
    className,
    required,
    ...props
}: BaseMultiselectProps) => {
    const localId = useId();

    return (
        <div className={className}>
            <label className="block mb-2" htmlFor={localId}>{label}{required ? '*' : ''}</label>
            {
                control && props.name &&
                <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <MultiSelect
                            {...field}
                            id={localId}
                            className={props.pt?.wrapper?.className}
                            name={field.name}
                            placeholder={props.placeholder ?? fieldsPlaceholders.dropdown}
                            variant={props.variant ?? 'filled'}
                            invalid={fieldState.invalid}
                            disabled={field.disabled}
                            {...props}
                        />
                    )}
                />
            }
            {
                !control &&
                <MultiSelect
                    id={localId}
                    {...props}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseMultiselect;
