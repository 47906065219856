import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import ContentLoadingTemplate from '../../templates/ContentLoadingTemplate';
import { IL_ROWS_NO } from '@/config/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ComplaintAudit, GetUserActivityLogListRequest, User } from "@/stub";
import { useDebounce } from "react-use";
import { produce } from "immer";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import { formatToUKDate } from "@/Util/formatToUKDate";
import AuditUserPreview from "@/components/Core/User/AuditUserPreview";
import { useGetUserActivityLog } from "@/Service/Api/ApiHooks/Users/useGetUserActivityLog";
import BaseSwitchInput from "@/components/Core/Form/BaseSwitchInput";

const AuditWrap = styled.div`
    .audits-list-wrap {
        border-right: 1px solid var(--gray-200);
        padding-right: 10px;

        .audit-item-wrap {
            padding: 5px 10px;
            opacity: 0.7;
            cursor: pointer;
            border-radius: 4px;

            &:hover, &.active {
                opacity: 1;
                background-color: var(--gray-200);
            }

            .audit-user, .audit-date, .audit-desc {
                font-size: 13px;
            }

            .audit-user {
                font-weight: bold;
            }

            .audit-desc {
                height: 1.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .audit-preview {
        .user-info {
            .user-name {
                font-weight: bold;
            }

            .audit-date {
                font-size: 13px;
                color: var(--gray-700);
            }
        }

        .audit-description {
            background: var(--gray-100);
            padding: 0.5rem 1rem 1rem 1rem;
            border-radius: 4px;
        }
    }

    .loader-wrap {
        position: relative;
        height: 60px;
    }

    .no-audits {
        padding: 1.5rem;
    }

    .search-input-wrap {
        i.pi-times {
            position: absolute;
            right: 0.4rem;
            top: 1rem;
        }
    }
`;

export type AuditUserProps = {
    user: User | undefined
};

/**
 * Component which renders a case audit (logs list)
 */
const AuditUser: React.FC<AuditUserProps> = ({ user }: AuditUserProps) => {
    const [audits, setAudits] = useState<ComplaintAudit[]>([]);
    const [selectedAudit, setSelectedAudit] = useState<ComplaintAudit | undefined>(undefined);
    const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

    const [requestParams, setRequestParams] = useState<GetUserActivityLogListRequest>({
        user_id: user?.id,
        is_subject: false,
        limit: IL_ROWS_NO,
        page: 1,
        search: undefined
    });

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const onNext = () => {
        setRequestParams(produce(draft => {
            draft.page += 1;
        }));
    };

    const onSubjectSwitch = (e) => {
        setRequestParams(produce(draft => {
            setSelectedAudit(undefined);
            setAudits([]);
            draft.is_subject = e.value;
        })
        );
    };

    useDebounce(
        () => {
            setRequestParams(produce(draft => {
                draft.search = searchInput;
                draft.page = 1;
                if (searchInput !== undefined) {
                    setSelectedAudit(undefined);
                    setAudits([]);
                }
            }));
        },
        500,
        [searchInput]
    );

    const {
        data: auditsData,
        isLoading: loadingAudits
    } = useGetUserActivityLog(requestParams);

    useEffect(() => {
        if (auditsData?.data) {
            if (!!audits.length) {
                setAudits([...new Map([...audits, ...auditsData.data].map(item => [item.id, item])).values()]);
            } else {
                setAudits(auditsData.data);
            }
        }
    }, [auditsData]);

    return (
        <AuditWrap>
            <div className="grid overflow-auto">
                <div className="col-6">
                    <div className="audits-list-wrap">
                        <div className='flex justify-content-between'>
                            <div className="p-input-icon-left search-input-wrap mb-3">
                                <BaseTextInput
                                    placeholder="Search audit"
                                    onChange={onSearch}
                                />
                            </div>
                            <BaseSwitchInput
                                tooltip={requestParams.is_subject ? 'Show only actions made by user' : 'Show only actions against user'}
                                checked={requestParams.is_subject ?? false}
                                onChange={onSubjectSwitch}
                            />
                        </div>

                        {audits && <InfiniteScroll
                            height="50vh"
                            dataLength={audits.length}
                            next={onNext}
                            hasMore={auditsData?.meta.current_page !== auditsData?.meta.last_page}
                            loader={<div className="loader-wrap"><ContentLoadingTemplate/></div>}
                            endMessage={audits.length == 0 && <div className="no-audits">No audits found.</div>}
                            showLoader={loadingAudits}
                        >
                            {audits.map((audit) => {
                                return (
                                    <div key={audit.id}
                                        className={'audit-item-wrap' + (selectedAudit === audit ? ' active' : '')}
                                        onClick={() => {
                                            setSelectedAudit(audit);
                                        }}>
                                        <div className="flex justify-content-between">
                                            <span className="audit-user">{audit.name}</span>
                                            <span className="audit-date">{formatToUKDate(audit.created_at, true)}</span>
                                        </div>

                                        <div className="audit-desc">{audit.description}</div>
                                    </div>
                                );
                            })}
                        </InfiniteScroll>}
                    </div>
                </div>

                <div className="col-6">
                    <div className="audit-preview">
                        {selectedAudit && <AuditUserPreview audit={selectedAudit}/>}
                    </div>
                </div>
            </div>
            <hr/>
        </AuditWrap>
    );
};

export default AuditUser;
