import { useQuery } from '@tanstack/react-query';
import { GetRootCausesActivityLogListRequest } from '@/stub';
import { rootCauseApiClient } from '@/Service/Api/ApiClients/RootCauseApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetRootCauseActivityLogsParams = {
    requestParams?: GetRootCausesActivityLogListRequest
    enabled?: boolean
};

export const useGetRootCauseActivityLogs = ({
    enabled = true,
    requestParams
}: useGetRootCauseActivityLogsParams) => useQuery({
    ...QueryKeys.rootCauses.activityLog(requestParams),
    queryFn: async () => await rootCauseApiClient.getRootCausesActivityLogList(requestParams),
    enabled
});
