import { ToastMessage } from "primereact/toast";

export const ComplaintDocumentSentDateSavedMessage: ToastMessage = {
    severity: 'success',
    summary: 'Processed',
    detail: 'Date sent was successfully set',
};


export const ComplaintAllDocumentSentDateSavedMessage: ToastMessage = {
    severity: 'success',
    summary: 'Processed',
    detail: 'All actions successfully clean',
};
