/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivityLogEvent,
  GetUserActivityLogList200Response,
  GetUsersFromTeam200Response,
  SortBy,
  SortOrder,
  StoreUserRequest,
  UpdateUserRequest,
  UpdateUserRolesRequest,
  User,
} from '../models/index';
import {
    ActivityLogEventFromJSON,
    ActivityLogEventToJSON,
    GetUserActivityLogList200ResponseFromJSON,
    GetUserActivityLogList200ResponseToJSON,
    GetUsersFromTeam200ResponseFromJSON,
    GetUsersFromTeam200ResponseToJSON,
    SortByFromJSON,
    SortByToJSON,
    SortOrderFromJSON,
    SortOrderToJSON,
    StoreUserRequestFromJSON,
    StoreUserRequestToJSON,
    UpdateUserRequestFromJSON,
    UpdateUserRequestToJSON,
    UpdateUserRolesRequestFromJSON,
    UpdateUserRolesRequestToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface CreateUserRequest {
    StoreUserRequest?: StoreUserRequest;
}

export interface GetUserRequest {
    user_id: number;
    organisation_id?: number;
}

export interface GetUserActivityLogListRequest {
    user_id: number;
    search?: string;
    limit?: number;
    page?: number;
    is_subject?: boolean;
    event?: ActivityLogEvent;
    log_name?: GetUserActivityLogListLogNameEnum;
    date_from?: string;
    date_to?: string;
    sort_order?: SortOrder;
    sort_by?: SortBy;
}

export interface GetUsersFromOrganisationRequest {
    limit?: number;
    search?: string;
    page?: number;
    organisation_id?: number;
}

export interface UpdateUserOperationRequest {
    user_id: number;
    UpdateUserRequest?: UpdateUserRequest;
}

export interface UpdateUserRolesOperationRequest {
    user_id: number;
    UpdateUserRolesRequest?: UpdateUserRolesRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Create User
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreUserRequestToJSON(requestParameters.StoreUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Create User
     */
    async createUser(requestParameters: CreateUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get  user
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get  user
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of activity-log for user
     */
    async getUserActivityLogListRaw(requestParameters: GetUserActivityLogListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserActivityLogList200Response>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling getUserActivityLogList.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.is_subject !== undefined) {
            queryParameters['is_subject'] = requestParameters.is_subject;
        }

        if (requestParameters.event !== undefined) {
            queryParameters['event'] = requestParameters.event;
        }

        if (requestParameters.log_name !== undefined) {
            queryParameters['log_name'] = requestParameters.log_name;
        }

        if (requestParameters.date_from !== undefined) {
            queryParameters['date_from'] = requestParameters.date_from;
        }

        if (requestParameters.date_to !== undefined) {
            queryParameters['date_to'] = requestParameters.date_to;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/activity-log`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserActivityLogList200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of activity-log for user
     */
    async getUserActivityLogList(requestParameters: GetUserActivityLogListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserActivityLogList200Response> {
        const response = await this.getUserActivityLogListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of users from organisation
     */
    async getUsersFromOrganisationRaw(requestParameters: GetUsersFromOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersFromTeam200Response>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersFromTeam200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of users from organisation
     */
    async getUsersFromOrganisation(requestParameters: GetUsersFromOrganisationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersFromTeam200Response> {
        const response = await this.getUsersFromOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User
     */
    async updateUserRaw(requestParameters: UpdateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestToJSON(requestParameters.UpdateUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Update User
     */
    async updateUser(requestParameters: UpdateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update user roles
     */
    async updateUserRolesRaw(requestParameters: UpdateUserRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.user_id === null || requestParameters.user_id === undefined) {
            throw new runtime.RequiredError('user_id','Required parameter requestParameters.user_id was null or undefined when calling updateUserRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/roles`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.user_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRolesRequestToJSON(requestParameters.UpdateUserRolesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * update user roles
     */
    async updateUserRoles(requestParameters: UpdateUserRolesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.updateUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetUserActivityLogListLogNameEnum = {
    Old: 'old',
    ComplaintAction: 'complaint_action',
    ComplaintContact: 'complaint_contact',
    ComplaintFos: 'complaint_fos',
    ComplaintCustomer: 'complaint_customer',
    ComplaintPoint: 'complaint_point',
    Complaint: 'complaint',
    ComplaintDecision: 'complaint_decision',
    ComplaintLetter: 'complaint_letter',
    ComplaintDocument: 'complaint_document',
    ComplaintEvidence: 'complaint_evidence',
    ComplaintOutcomes: 'complaint_outcomes',
    User: 'user',
    RootCause: 'root_cause'
} as const;
export type GetUserActivityLogListLogNameEnum = typeof GetUserActivityLogListLogNameEnum[keyof typeof GetUserActivityLogListLogNameEnum];
