import { useMutation } from '@tanstack/react-query';
import { DownloadFileRequest } from '@/stub';
import { filesApiClient } from "@/Service/Api/ApiClients/FilesApiClient";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";

export const useDownloadFile = () => useMutation({
    mutationFn: async (requestParams: DownloadFileRequest) => {
        try {
            return await filesApiClient.downloadFile(requestParams);
        } catch (error) {
            await processValidationErrors(error);
            throw error;
        }
    }
});
