import { ComplaintAudit } from "@/stub";
import { getUserAvatar, getUserFullName } from "@/helpers/user";
import { snakeToNormalCase } from "@/helpers/general";
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import styled from "styled-components";
import { formatToUKDate } from "@/Util/formatToUKDate";

const StyledWrap = styled.div`
    .table {
        width: 100%;
        margin: 0.5rem 0 1rem 0;

        thead {
            th {
                text-align: left;
                padding: 1rem 0.5rem;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid var(--gray-200);

                &:hover {
                    background-color: var(--gray-100);
                }

                &:last-child {
                    border: 0;
                }
            }

            td {
                padding: 1rem 0.5rem;
            }
        }
    }
`;

export type AuditPreviewProps = BaseFormInputProps & {
    audit?: ComplaintAudit
};

const AuditPreview = ({ audit }: AuditPreviewProps) => {
    if (!audit) return undefined;
    const beforeAndAfter = audit.properties.old && audit.properties.attributes;
    const auditEntries = audit.properties.attributes ?? audit.properties.old;

    return (
        <StyledWrap>
            <div className="user-info mb-3 flex">
                {getUserAvatar(audit.user)}

                <div>
                    <div
                        className="user-name">{audit.user ? getUserFullName(audit.user) : 'System'}</div>
                    <div className="audit-date">{formatToUKDate(audit.created_at, true)}</div>
                </div>
            </div>

            <div className="audit-description">{audit.description}</div>
            <table className="table">
                {beforeAndAfter && <thead>
                    <tr>
                        <th>Field</th>
                        <th>Before</th>
                        <th>After</th>
                    </tr>
                </thead>}
                <tbody>
                    {Object.keys(auditEntries).map((key, index) => {
                        return (
                            <tr key={index}>
                                <td>{snakeToNormalCase(key)}</td>
                                <td dangerouslySetInnerHTML={{ __html: auditEntries[key] }}></td>
                                {beforeAndAfter && <td dangerouslySetInnerHTML={{ __html: audit.properties.old[key] }}></td>}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </StyledWrap>
    );
};

export default AuditPreview;
