import {
    ChannelType,
    type Complaint,
    ContactChannelType,
    UpdateComplaintChannelRequest
} from '@/stub';
import { useQueryClient } from '@tanstack/react-query';
import ComplaintChannelSelect from "@/components/Core/Form/Selector/ComplaintChannelSelect";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { z } from "zod";
import { COMPLAINT_ALLOWED_EXTENSIONS, FILE_UPLOAD_MAX_SIZE } from "@/config/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateComplaintChannel } from "@/Service/Api/ApiHooks/ComplaintChannel/useUpdateComplaintChannel";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { ComplaintChannelUpdatedMessage } from "@/Messages/Toast/Complaint/ComplaintChannelUpdatedMessage";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { useGetComplaintChannel } from "@/Service/Api/ApiHooks/ComplaintChannel/useGetComplaintChannel";
import { useEffect, useMemo } from "react";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { useFormStateStore } from "@/Stores/FormStore";
import { isEmpty } from "@/Util/isEmptyObject";
import { normalizeContent } from "@/Util/normalizeContent";

type ChannelProps = {
    complaint?: Complaint
};

const ChannelSchema = z.object({
    channel: z.object({
        content: z.string({
            invalid_type_error: 'Content must be a string'
        }).optional(),
        from: z.string({
            invalid_type_error: 'From must be a string'
        }).optional(),
        to: z.string({
            invalid_type_error: 'To must be a string'
        }).optional(),
        description: z.string({
            invalid_type_error: 'Description must be a string'
        }).optional(),
        channel_type: z.nativeEnum(ChannelType, {
            required_error: 'Channel is required',
            invalid_type_error: 'Channel type must be a string'
        }).optional(),
        files: z.array(z.instanceof(File)
            .refine((file?: File) => !file || file.size <= FILE_UPLOAD_MAX_SIZE, `Max upload size is ${FILE_UPLOAD_MAX_SIZE / 1048576} MB`)
            .refine((file?: File) => !file || COMPLAINT_ALLOWED_EXTENSIONS.includes(file.type ?? ''), 'File format not supported'))
            .optional()
    }).optional()
});

export type ChannelFormData = z.infer<typeof ChannelSchema>;

const Channel = ({ complaint }: ChannelProps) => {
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const queryClient = useQueryClient();
    const setDirty = useFormStateStore((state) => state.setDirty);

    const {
        data: channelData
    } = useGetComplaintChannel({
        requestParams: {
            channel_id: complaint?.channel?.id,
            complaint_id: complaint?.id,
        },
        enabled: !!complaint?.channel?.id,
        select: (data) => ({
            channel: {
                ...data,
                document: data?.document ? [data?.document] : []
            }
        })
    });

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { errors, dirtyFields }
    } = useForm<ChannelFormData>({
        resolver: zodResolver(ChannelSchema),
        defaultValues: useMemo(() => {
            return {
                channel: channelData
            };
        }, [channelData])
    });

    const updateComplaintChannelMutation = useUpdateComplaintChannel({ setError });

    const validate = (data: ChannelFormData) => {
        return ChannelSchema.safeParse(data).success;
    };

    const onSubmit = async (data: ChannelFormData) => {
        if (validate(data)) {
            const dataToSend: UpdateComplaintChannelRequest = {
                ...data.channel,
                contact_type: ContactChannelType.OnComplaintCreate,
                complaint_id: complaint.id,
                channel_id: complaint?.channel?.id,
                file: data.channel?.files?.[0] ?? undefined
            };

            await updateComplaintChannelMutation.mutateAsync(dataToSend, {
                onSuccess: async () => {
                    void Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.complaints.detail(complaint?.id).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.channels.detail(complaint?.channel?.id).queryKey,
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                        })
                    ]);

                    addToastMessage(ComplaintChannelUpdatedMessage);
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            });
        }
    };

    useEffect(() => {
        if(channelData) reset(normalizeContent(channelData));
    }, [channelData]);


    const isDirty = !isEmpty(dirtyFields);
    useEffect(() => {
        setDirty(isDirty);
    }, [isDirty]);

    return <form onSubmit={handleSubmit(onSubmit)}>
        <ComplaintChannelSelect
            control={control}
            required
            disabled={isReadonlyComplaint(complaint)}
            complaintId={complaint.id}
            errors={errors}
            existingFiles={channelData?.channel?.document}
        />
        <Divider/>
        <div className="flex">
            <Button
                type="submit"
                label='Submit'
                className="ml-auto"
                icon='pi pi-check-circle'
                iconPos="right"
                disabled={isReadonlyComplaint(complaint)}
                loading={updateComplaintChannelMutation.isPending}
            />
        </div>
    </form>;
};

export default Channel;
