import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { clsx } from 'clsx';
import { z } from 'zod';
import { Button } from 'primereact/button';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseRootCauseFcaSelect from "@/components/Core/Form/Selector/BaseRootCauseFcaSelect";

const FcaRootCauseMappingFormSchema = z.object({
    root_cause_id: z.number(),
    fca_root_cause_id: z.number(),
    label: z.string(),
});

export type FcaRootCauseMappingFormData = z.infer<typeof FcaRootCauseMappingFormSchema>;

export type FcaRootCauseMappingFormProps = {
    className?: string
    onSubmit?: (data: FcaRootCauseMappingFormData) => void
    onSubmitButtonLabel?: string
    onSubmitButtonDisabled?: boolean
    onSubmitButtonSeverity?: "secondary" | "success" | "info" | "warning" | "danger" | "help" | undefined
    isProcessing?: boolean
    initFormData?: Partial<FcaRootCauseMappingFormData>
};

const FcaRootCauseMappingForm: React.FC<FcaRootCauseMappingFormProps> = ({
    className,
    onSubmit,
    isProcessing,
    initFormData,
    onSubmitButtonLabel,
    onSubmitButtonDisabled = false,
    onSubmitButtonSeverity = 'success'
}: FcaRootCauseMappingFormProps) => {
    const initialFormData = useMemo(() => {
        return {
            root_cause_id: initFormData?.root_cause_id ?? undefined,
            fca_root_cause_id: initFormData?.fca_root_cause_id ?? undefined,
            label: initFormData?.label ?? undefined,
        };
    }, [initFormData]);

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<FcaRootCauseMappingFormData>({
        resolver: zodResolver(FcaRootCauseMappingFormSchema),
        defaultValues: initialFormData
    });

    const onFormSubmit = (data: FcaRootCauseMappingFormData) => {
        if (onSubmit) {
            onSubmit(data);
        }
    };

    return (
        <div className={clsx(className, 'fca-root-cause-form')}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="flex gap-3 flex-column">
                    <BaseRootCauseFcaSelect
                        name={'fca_root_cause_id'}
                        label="FCA Root Cause"
                        required
                        control={control}
                        queryEnabled={true}
                        selectableLvl={2}
                        className="rounded-md w-full"
                        placeholder="Root Cause"
                        showClear
                        errorMessages={errors.fca_root_cause_id?.message}
                    />
                </div>
                <div className="flex mt-4">
                    <Button
                        className="ml-auto"
                        label={onSubmitButtonLabel}
                        disabled={onSubmitButtonDisabled}
                        type="submit"
                        severity={onSubmitButtonSeverity}
                        loading={isProcessing}
                    />
                </div>
            </form>
        </div>
    );
};

export default FcaRootCauseMappingForm;
