import React from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import { createGlobalStyle } from "styled-components";
import RootCauseForm, { RootCauseFormFormProps } from "@/components/Core/RootCause/RootCauseForm";

export type RootCauseModalProps = {
    dialogProps: DialogProps
    rootCauseFormProps: RootCauseFormFormProps
};

const RootCauseModalStyle = createGlobalStyle`
    .root-cause-form {
        width: 100%;
    }
`;

const RootCauseModal: React.FC<RootCauseModalProps> = ({ dialogProps, rootCauseFormProps }: RootCauseModalProps) => {

    return (
        <>
            <RootCauseModalStyle/>
            <Dialog
                draggable={false}
                blockScroll
                {...dialogProps}
            >
                <RootCauseForm
                    {...rootCauseFormProps}
                />
            </Dialog>
        </>
    );
};

export default RootCauseModal;
