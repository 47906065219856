import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { setDocumentTitle } from "@/helpers/general";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import ContentHeader from "@/components/Blocks/ContentHeader";
import styled from "styled-components";
import OrganisationUserDatable from "@/components/Datatables/OrganisationUserDatable";
import { useDebounce } from "react-use";
import { GetUsersFromOrganisationRequest, StoreUserRequestAdditionalRolesEnum } from "@/stub";
import { Button } from "primereact/button";
import NewUserModal from "@/components/Modals/NewUserModal";
import { UserFormData } from "@/components/Core/User/UserForm";
import { useCreateUser } from "@/Service/Api/ApiHooks/Users/useCreateUser";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { UserCreatedMessage } from "@/Messages/Toast/Users/UserCreatedMessage";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { authUserCan } from "@/Util/permissionChecks";


const StyledWrap = styled.main`
    .content-container {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .page-actions {
        margin-top: 2rem;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
    }
`;


export type OrganisationUsersPageProps = {
    className?: string
};

/**
 * Organisation Users Page
 */
const OrganisationUsersPage: React.FC = ({ className }: OrganisationUsersPageProps) => {
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const { mutateAsync: createUser, isPending, isError, error } = useCreateUser();
    const queryClient = useQueryClient();
    const [searchInput, setSearchInput] = useState<string>('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>(searchInput);
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

    const handleCreateUser = async (data: UserFormData) => {
        await createUser({
            StoreUserRequest: {
                ...data,
                additional_roles: data.additional_roles as unknown as StoreUserRequestAdditionalRolesEnum
            }
        }, {
            onSuccess: () => {
                addToastMessage(UserCreatedMessage);
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.users.list(requestParams).queryKey
                });
                setIsDialogVisible(false);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    useDebounce(
        () => {
            setDebouncedSearchInput(searchInput);
        },
        600,
        [searchInput]
    );
    useEffect(() => {
        setDocumentTitle('Organisation Users');
    }, []);

    const requestParams = useMemo<GetUsersFromOrganisationRequest>(() => {

        return {
            search: debouncedSearchInput ?? undefined
        };
    }, [debouncedSearchInput]);

    return (
        <div>
            <StyledWrap className={className}>
                <ContentHeader
                    title={'Organisation Users'}
                    description={'Forseti Complaints Manager'}
                    rightSectionTemplate={
                        <div className={'flex gap-3'}>
                            <BaseTextInput
                                value={searchInput || ''}
                                placeholder="Search"
                                onChange={(e) => {
                                    setSearchInput(e.target.value);
                                }}
                            />
                            {authUserCan('create:org_users') &&
                                <Button onClick={() => setIsDialogVisible(true)}> Create New User</Button>}
                        </div>
                    }
                />
            </StyledWrap>

            <div className="content-container">
                <OrganisationUserDatable requestParams={requestParams}/>
            </div>

            <NewUserModal
                dialogProps={{
                    visible: isDialogVisible,
                    className: 'w-5',
                    onHide: () => {
                        setIsDialogVisible(false);
                    }
                }}
                userFormProps={{
                    onSubmit: handleCreateUser,
                    onSubmitButtonLabel: 'Create',
                    onSubmitButtonDisabled: false,
                    isProcessing: isPending,
                    errorBag: isError ? [error] : undefined
                }}
            />
        </div>
    );
};

export default withAuthenticationRequired(OrganisationUsersPage);
