import { useEffect, useRef } from 'react';
import { BrowserRouter, Navigate, Route, Routes, Link } from 'react-router-dom';
import { routes } from '@/config/routes';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useAuth0 } from '@auth0/auth0-react';
import PrivateRoute from './auth0/ProtectedRoute';
import ContentLoadingTemplate from './templates/ContentLoadingTemplate';
import NotFoundPage from '../pages/NotFoundPage';

/**
 * Site's main routing page
 */
const App = () => {
    const toastBCRef = useRef(null);

    const { isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        if (!localStorage.getItem('accepted_cookies') && toastBCRef.current) {
            toastBCRef.current.show({
                severity: 'info',
                sticky: true,
                closable: false,
                content: (<>
                    <div>
                        This website uses cookies so that we can improve your experience. By continuing to use the site you are agreeing to our use of cookies. For more details
                        please view our <Link to={'/cookies'} className="p-link">Cookies Policy</Link>.
                    </div>
                    <Button className="p-button-outlined p-button-secondary p-button-sm block mt-3" onClick={() => {
                        localStorage.setItem('accepted_cookies', true);
                        toastBCRef.current.clear();
                    }}>Accept and Close</Button>
                </>)
            });
        }
    }, []);

    return (
        <BrowserRouter>
            {isLoading
                ? <ContentLoadingTemplate/>
                : <>
                    <Routes>
                        {isAuthenticated ?
                            <Route element={<PrivateRoute/>}>
                                {routes.private.map((route, key) => {
                                    return <Route key={key} path={route.path} element={route.component}/>;
                                })}
                            </Route>
                            : routes.public.map((route, key) => {
                                return <Route key={key} path={route.path} element={route.component}/>;
                            })
                        }
                        <Route path="/404" element={<NotFoundPage/>}/>;
                        <Route path="*" element={<Navigate replace to="/404"/>}/>;
                    </Routes>

                    <Toast ref={toastBCRef} className="cookies-notification" position="bottom-left"/>
                </>}
        </BrowserRouter>
    );
};

export default App;
