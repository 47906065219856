/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppHttpRequestsApiV2ComplaintRefreshFosCaseFileRequest,
  ComplaintFos,
  ComplaintSortBy,
  GetComplaints200Response,
  OAFosState,
  SortOrder,
  StoreComplaintFosRequest,
  UpdateComplaintFosRequest,
} from '../models/index';
import {
    AppHttpRequestsApiV2ComplaintRefreshFosCaseFileRequestFromJSON,
    AppHttpRequestsApiV2ComplaintRefreshFosCaseFileRequestToJSON,
    ComplaintFosFromJSON,
    ComplaintFosToJSON,
    ComplaintSortByFromJSON,
    ComplaintSortByToJSON,
    GetComplaints200ResponseFromJSON,
    GetComplaints200ResponseToJSON,
    OAFosStateFromJSON,
    OAFosStateToJSON,
    SortOrderFromJSON,
    SortOrderToJSON,
    StoreComplaintFosRequestFromJSON,
    StoreComplaintFosRequestToJSON,
    UpdateComplaintFosRequestFromJSON,
    UpdateComplaintFosRequestToJSON,
} from '../models/index';

export interface CreateFosInComplaintRequest {
    complaint_id: number;
    StoreComplaintFosRequest?: StoreComplaintFosRequest;
}

export interface GetFosComplaintsRequest {
    search?: string;
    sort_by?: ComplaintSortBy;
    sort_order?: SortOrder;
    fos_states?: Array<OAFosState>;
    page?: number;
    limit?: number;
    organisation_id?: number;
}

export interface GetFosFromComplaintRequest {
    complaint_id: number;
}

export interface RefreshFosArchiveRequest {
    complaint_id: number;
    fos_id: number;
    body?: AppHttpRequestsApiV2ComplaintRefreshFosCaseFileRequest;
}

export interface UpdateFosInComplaintRequest {
    complaint_id: number;
    fos_id: number;
    UpdateComplaintFosRequest?: UpdateComplaintFosRequest;
}

/**
 * 
 */
export class ComplaintFOSApi extends runtime.BaseAPI {

    /**
     * Create/update complaint fos
     */
    async createFosInComplaintRaw(requestParameters: CreateFosInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplaintFos>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling createFosInComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/fos`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreComplaintFosRequestToJSON(requestParameters.StoreComplaintFosRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFosFromJSON(jsonValue));
    }

    /**
     * Create/update complaint fos
     */
    async createFosInComplaint(requestParameters: CreateFosInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplaintFos> {
        const response = await this.createFosInComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Complaints with Fos
     */
    async getFosComplaintsRaw(requestParameters: GetFosComplaintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComplaints200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort_by !== undefined) {
            queryParameters['sort_by'] = requestParameters.sort_by;
        }

        if (requestParameters.sort_order !== undefined) {
            queryParameters['sort_order'] = requestParameters.sort_order;
        }

        if (requestParameters.fos_states) {
            queryParameters['fos_states[]'] = requestParameters.fos_states;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.organisation_id !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisation_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComplaints200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of Complaints with Fos
     */
    async getFosComplaints(requestParameters: GetFosComplaintsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComplaints200Response> {
        const response = await this.getFosComplaintsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the complaint fos
     */
    async getFosFromComplaintRaw(requestParameters: GetFosFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplaintFos>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getFosFromComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/fos`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFosFromJSON(jsonValue));
    }

    /**
     * Get the complaint fos
     */
    async getFosFromComplaint(requestParameters: GetFosFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplaintFos> {
        const response = await this.getFosFromComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refresh Fos Archive
     */
    async refreshFosArchiveRaw(requestParameters: RefreshFosArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling refreshFosArchive.');
        }

        if (requestParameters.fos_id === null || requestParameters.fos_id === undefined) {
            throw new runtime.RequiredError('fos_id','Required parameter requestParameters.fos_id was null or undefined when calling refreshFosArchive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/fos/{fos_id}/refresh-archive`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"fos_id"}}`, encodeURIComponent(String(requestParameters.fos_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Refresh Fos Archive
     */
    async refreshFosArchive(requestParameters: RefreshFosArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.refreshFosArchiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update complaint fos
     */
    async updateFosInComplaintRaw(requestParameters: UpdateFosInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplaintFos>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updateFosInComplaint.');
        }

        if (requestParameters.fos_id === null || requestParameters.fos_id === undefined) {
            throw new runtime.RequiredError('fos_id','Required parameter requestParameters.fos_id was null or undefined when calling updateFosInComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/fos/{fos_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"fos_id"}}`, encodeURIComponent(String(requestParameters.fos_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateComplaintFosRequestToJSON(requestParameters.UpdateComplaintFosRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintFosFromJSON(jsonValue));
    }

    /**
     * Update complaint fos
     */
    async updateFosInComplaint(requestParameters: UpdateFosInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplaintFos> {
        const response = await this.updateFosInComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
