import React from 'react';
import { Complaint, ComplaintInvestigation, ComplaintPoints, ComplaintStateEnum } from '@/stub';
import { Button } from 'primereact/button';
import ErrorsBlock from "@/components/Core/Form/ErrorsBlock";
import type { BaseFormInputProps } from "@/Type/BaseFormInputProps";
import { FieldArrayWithId } from "react-hook-form";
import ComplaintEvidenceInputForm from "@/components/Core/Investigation/ComplaintEvidenceInputForm";
import {
    useGetEvidenceConsideredTypes
} from "@/Service/Api/ApiHooks/ComplaintInvestigation/useGetEvidenceConsideredTypes";
import { isReadonlyComplaint } from "@/Util/permissionChecks";

export type ComplaintEvidenceFormProps = BaseFormInputProps & {
    complaint: Complaint
    evidenceFields: FieldArrayWithId<ComplaintInvestigation, "evidence", "id">[]
    addEvidence: () => void
    removeEvidence: (index: number) => void
    availablePoints: ComplaintPoints[]
    setValue: (name: string, value: never) => void
};

const ComplaintEvidenceForm: React.FC<ComplaintEvidenceFormProps> = ({
    complaint,
    control,
    evidenceFields,
    errorMessages,
    addEvidence,
    removeEvidence,
    availablePoints,
    setValue,
}: ComplaintEvidenceFormProps) => {
    const {
        data: evidenceConsideredTypes
    } = useGetEvidenceConsideredTypes();

    return <div className="mb-5">
        <h4 className="font-semibold">Evidence Considered</h4>
        <ErrorsBlock
            className="block p-error pt-0"
            errorMessages={errorMessages?.evidence?.message}
        />
        <div>
            {evidenceFields.map((evidence, index) => (
                <ComplaintEvidenceInputForm
                    key={evidence.id}
                    evidence={evidence}
                    availablePoints={availablePoints}
                    complaint={complaint}
                    index={index}
                    errorMessages={errorMessages}
                    control={control}
                    removeEvidence={removeEvidence}
                    evidenceConsideredTypes={evidenceConsideredTypes ?? []}
                    setValue={setValue}
                />
            ))}
            <Button
                className='p-button-outlined p-button-secondary p-button-sm'
                label="Add evidence"
                icon="pi pi-plus"
                disabled={isReadonlyComplaint(complaint)}
                onClick={(event) => {
                    event.preventDefault();
                    addEvidence();
                }}
            />
        </div>

    </div>;
};

export default ComplaintEvidenceForm;
