import React, { useMemo } from 'react';
import BaseTextInput from '@/components/Core/Form/BaseTextInput';
import { useForm } from 'react-hook-form';
import { clsx } from 'clsx';
import { z } from 'zod';
import { Button } from 'primereact/button';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseRootCauseSelect from "@/components/Core/Form/Selector/BaseRootCauseSelect";
import { RootCauseStatus } from "@/stub";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";

const RootCauseFormSchema = z.object({
    id: z.number().optional(),
    label: z.string(),
    lvl: z.number().optional(),
    parent_id: z.number(),
    status: z.nativeEnum(RootCauseStatus),
}).superRefine((data, ctx) => {
    if (!data.parent_id) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Parent root is required',
            path: ['parent_id']
        });
    }
});

export type RootCauseFormData = z.infer<typeof RootCauseFormSchema>;

type RequiredFields = Pick<RootCauseFormData, 'label' | 'parent_id'>;
type OptionalFields = Partial<Omit<RootCauseFormData, 'label' | 'parent_id'>>;

export type RootCauseInfoInitData = RequiredFields & OptionalFields;

export type RootCauseFormFormProps = {
    className?: string
    onSubmit?: (data: RootCauseInfoInitData) => void
    onSubmitButtonLabel?: string
    onSubmitButtonDisabled?: boolean
    onSubmitButtonSeverity?: "secondary" | "success" | "info" | "warning" | "danger" | "help" | undefined
    isProcessing?: boolean
    initFormData?: Partial<RootCauseInfoInitData>
};

const rootCauseStatusOptions = Object.values(RootCauseStatus) as RootCauseStatus[];

const RootCauseForm: React.FC<RootCauseFormFormProps> = ({
    className,
    onSubmit,
    isProcessing,
    initFormData,
    onSubmitButtonLabel,
    onSubmitButtonDisabled = false,
    onSubmitButtonSeverity = 'success'
}: RootCauseFormFormProps) => {
    const initialFormData = useMemo(() => {
        return {
            id: initFormData?.id ?? undefined,
            label: initFormData?.label ?? '',
            parent_id: initFormData?.parent_id ?? 0,
            lvl: initFormData?.lvl ?? 0,
            status: initFormData?.status ?? RootCauseStatus.Active
        };
    }, [initFormData]);

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues
    } = useForm<RootCauseInfoInitData>({
        resolver: zodResolver(RootCauseFormSchema),
        defaultValues: initialFormData
    });

    const onFormSubmit = (data: RootCauseInfoInitData) => {
        if (onSubmit) {
            onSubmit(data);
        }
    };

    return (
        <div className={clsx(className, 'root-cause-form')}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="flex gap-3 flex-column">
                    {!initialFormData?.id ? <BaseRootCauseSelect
                        name={'parent_id'}
                        label="Parent"
                        required
                        value={String(getValues('parent_id')) ?? null}
                        control={control}
                        selectableLvl={0}
                        isSelectedRoot
                        className="rounded-md w-full"
                        placeholder="Root Cause"
                        showClear
                        errorMessages={errors.parent_id?.message}
                    /> : null}
                    <BaseTextInput
                        className="rounded-md"
                        pt={{ inputText: { className: 'w-full' } }}
                        control={control}
                        required
                        name="label"
                        label={'Label'}
                        errorMessages={errors.label?.message}
                    />
                    <BaseDropdown
                        control={control}
                        name="status"
                        label="Status"
                        options={rootCauseStatusOptions}
                        required
                        errorMessages={errors.status?.message}
                    />
                </div>
                <div className="flex mt-4">
                    <Button
                        className="ml-auto"
                        label={onSubmitButtonLabel}
                        disabled={onSubmitButtonDisabled}
                        type="submit"
                        severity={onSubmitButtonSeverity}
                        loading={isProcessing}
                    />
                </div>
            </form>
        </div>
    );
};

export default RootCauseForm;
