import { CreateRootCauseRequestStatusEnum, GetRootCauseInTreeStructureRequest, RootCause, } from "@/stub";
import React, { useState } from "react";
import { Button } from "primereact/button";
import ContentHeader from "@/components/Blocks/ContentHeader";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import styled from "styled-components";
import { useDebounce } from "react-use";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { useCreateRootCause } from "@/Service/Api/ApiHooks/RootCause/useCreateRootCause";
import { RootCauseInfoInitData } from "@/components/Core/RootCause/RootCauseForm";
import RootCauseModal from "@/components/Modals/RootCauseModal";
import { RootCauseCreatedMessage } from "@/Messages/Toast/RootCauses/RootCauseCreatedMessage";
import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import RootCauseDatatable from "@/components/Datatables/RootCauseDatatable";
import RootCauseAuditDatatable from "@/components/Datatables/RootCauseAuditDatatable";
import {
    useGetRootCausesConfigDownloadDetails
} from "@/Service/Api/ApiHooks/RootCause/useGetRootCausesConfigDownloadDetails";
import { useDownloadFile } from "@/Service/Api/ApiHooks/Files/useDownloadFile";
import { downloadBlob } from "@/Util/downloadBlob";
import UploadRootCauseConfigModal from "@/components/Modals/UploadRootCauseConfigModal";
import { produce } from "immer";

const StyledWrap = styled.main`
    .content-container {
        padding: 0 1rem;
    }
`;

interface Tab {
    key: 'table' | 'audit';
    name: string;
}

const tabs: Tab[] = [
    {
        key: 'table',
        name: 'Tree Table',
    },
    {
        key: 'audit',
        name: 'Audit',
    },

];

const RootCausePage = () => {
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const [searchInput, setSearchInput] = useState('');
    const [dialogData, setDialogData] = useState<Partial<RootCauseInfoInitData | RootCause> | null>(null);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const { mutateAsync: createRootCause, isPending: isCreatePending } = useCreateRootCause();
    const [filters, setFilters] = useState<GetRootCauseInTreeStructureRequest & { globalFilter?: string }>({});

    const {
        refetch: getConfigDetails,
        isRefetching: isGetConfigDetailsPending
    } = useGetRootCausesConfigDownloadDetails({ enabled: false });
    const { mutate: downloadFile, isPending: isDownloadPending } = useDownloadFile();

    const [,] = useDebounce(
        () => {
            setFilters(produce(draft => {
                draft.globalFilter = searchInput;
            }));
        },
        1000,
        [searchInput]
    );

    const handleCreateNewRootCause = async (data: RootCauseInfoInitData) => {
        await createRootCause({
            CreateRootCauseRequest: {
                name: data.label,
                parent_id: data.parent_id,
                status: data.status as CreateRootCauseRequestStatusEnum
            }
        }, {
            onSuccess: () => {
                addToastMessage(RootCauseCreatedMessage);
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.rootCauses._def
                });
                queryClient.invalidateQueries({
                    queryKey: QueryKeys.activityLog._def
                });
                setDialogData(null);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const handleDownloadConfig = async () => {
        await getConfigDetails().then((configDetails) => {
            if (configDetails.error) {
                addToastMessage(CustomErrorMessage(configDetails.error));
            } else {
                downloadFile({
                    path: configDetails.data?.path as string
                }, {
                    onSuccess: (blob) => {
                        downloadBlob(blob, configDetails.data?.filename);
                    },
                    onError: error => {
                        addToastMessage(CustomErrorMessage(error));
                    }
                });
            }
        });

    };

    const generateTabContent: React.FC<Tab> = (tab) => {
        switch (tab.key) {
            case 'table':
                return (<RootCauseDatatable filters={filters} onAddChild={setDialogData}/>);

            case 'audit':
                return (<RootCauseAuditDatatable/>);

            default:
                return undefined;
        }
    };

    return (
        <div>
            <StyledWrap>
                <ContentHeader
                    title={'Root Causes'}
                    description={'Root Causes'}
                    rightSectionTemplate={
                        <div className="flex gap-1">
                            {activeTabIndex === 0 ?
                                <BaseTextInput
                                    value={searchInput}
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setSearchInput(e.target.value);
                                    }}
                                />
                                : undefined}
                            <Button
                                loading={isDownloadPending || isCreatePending || isGetConfigDetailsPending}
                                onClick={() => setDialogData({})}
                                label={'Create New Root Cause'}
                            />
                            <Button
                                loading={isDownloadPending || isCreatePending || isGetConfigDetailsPending}
                                onClick={() => handleDownloadConfig()}
                                label={'Download Config'}
                            />
                            <Button
                                loading={isDownloadPending || isCreatePending || isGetConfigDetailsPending}
                                onClick={() => setUploadModalVisible(true)}
                                label={'Upload Config'}
                            />
                        </div>
                    }
                />
                <div className="content-container">
                    <Card className="p-tight mt-2">
                        <TabView
                            id={'tabpanels'}
                            activeIndex={activeTabIndex}
                            onTabChange={(e) => {
                                setActiveTabIndex(e.index);
                            }}
                            className="main-tabs no-control-btns"
                            scrollable
                            content={'asdad'}
                        >
                            {tabs.map((tab) => {
                                return (
                                    <TabPanel header={tab.name} key={tab.key}>
                                        {generateTabContent(tab)}
                                    </TabPanel>
                                );

                            })}
                        </TabView>
                    </Card>
                </div>
            </StyledWrap>

            <RootCauseModal
                dialogProps={{
                    header: 'Create Root Cause',
                    visible: !!dialogData,
                    onHide: () => {
                        setDialogData(null);
                    }
                }}
                rootCauseFormProps={{
                    initFormData: dialogData as RootCauseInfoInitData,
                    onSubmit: handleCreateNewRootCause,
                    onSubmitButtonLabel: 'Create',
                    onSubmitButtonSeverity: 'info',
                    onSubmitButtonDisabled: false,
                    isProcessing: isCreatePending,
                }}
            />
            <UploadRootCauseConfigModal
                dialogProps={{
                    visible: uploadModalVisible,
                    onHide: () => setUploadModalVisible(false)
                }}
            />
        </div>
    );
};

export default RootCausePage;
