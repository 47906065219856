import React, { useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { CloudDownloadIcon } from "@/helpers/svg-icons";
import { FILE_UPLOAD_MAX_SIZE } from "@/config/constants";
import { classNames } from "primereact/utils";
import InfoTooltipTemplate from "../InfoTooltipTemplate";
import { formatFileSize } from "@/helpers/general";

/**
 * A customizable file upload template
 */
const FileUploadTemplate = ({
    name,
    className,
    contentClassName,
    value,
    mode = 'advanced',
    multiple = true,
    auto = true,
    accept,
    maxFileSize = FILE_UPLOAD_MAX_SIZE,
    uploadHandler,
    loading,
    onFileRemove,
    itemTemplate,
    disabled,
    chooseOptions,
    triggerClear,
    customProps,
    label,
    noMargins,
    bottomMargin,
    infoMessage,
    inline,
    isValid,
    errorMessage,
    labelClassName,
    category,
    formData,
    setFormData,
    onChangeFunction,
    extraOptions
}) => {
    const fileUploadRef = useRef();

    // const onFileRemove = (event) => {
    //     // TO DO: add a confirmation here
    //     console.log(event);
    // }

    const [inputClasses, setInputClasses] = useState(classNames({
        'p-invalid': isValid,
        'w-full': !inline
    }));
    useEffect(() => {
        if (triggerClear) {
            fileUploadRef.current.clear();
        }
    }, [triggerClear]);

    const [labelClasses, setLabelClasses] = useState(classNames({
        'p-error': isValid,
        'w-full': !inline,
        'block mb-2': !inline,
        'inline-block mr-3': inline,
        'pr-4': infoMessage
    }));

    const wrapClasses = classNames({
        'inline-input-wrap': inline,
        'flex': inline
    });

    useEffect(() => {
        // add label classes (if we have any)
        if (labelClassName) {
            setLabelClasses(labelClasses + ' ' + labelClassName);
        }

        // add input classes (if we have any)
        if (className) {
            setInputClasses(inputClasses + ' ' + className);
        }

        if (triggerClear) {
            fileUploadRef.current.clear();
        }

        // visually set a value (if there is one)
        if (Array.isArray(value) && value?.length > 0) {
            let uploadParentElement = document.getElementById(name),
                contentElement = uploadParentElement.querySelector('.p-fileupload-content'),
                newFilesParent = document.createElement('div'),
                showDeleteBtn = true;

            newFilesParent.setAttribute('class', 'p-fileupload-files custom-added-files');
            contentElement.appendChild(newFilesParent);

            if (extraOptions?.noDeleteBtn) {
                showDeleteBtn = false;
            }

            value.forEach((item) => {
                const newRowElement = document.createElement('div'),
                    fileNameElement = document.createElement('div'),
                    fileSizeElement = document.createElement('div'),
                    deleteFileElement = document.createElement('div'),
                    deleteFileButton = document.createElement('button'),
                    deleteFileIcon = document.createElement('span'),
                    fileName = document.createTextNode(item.filename),
                    fileSize = document.createTextNode(formatFileSize(item.size));

                // main row element
                newRowElement.setAttribute('class', 'p-fileupload-row');
                newFilesParent.appendChild(newRowElement);

                // file name
                fileNameElement.setAttribute('class', 'p-fileupload-filename');
                fileNameElement.appendChild(fileName);

                // file size
                fileSizeElement.appendChild(fileSize);

                if (showDeleteBtn) {
                    // delete file button
                    deleteFileButton.setAttribute('class', 'p-button p-component p-button-icon-only');
                    deleteFileButton.setAttribute('type', 'button');
                    deleteFileIcon.setAttribute('class', 'p-button-icon p-c pi pi-times');

                    // bind click delete file button
                    deleteFileButton.onclick = () => {
                        const filteredFiles = value.filter((file) => file.id != item.id);

                        onChangeFunction(name, filteredFiles?.length > 0 ? filteredFiles : null, category, formData, setFormData);
                        newRowElement.remove();
                    };
                }

                // append elements
                newRowElement.appendChild(fileNameElement);
                newRowElement.appendChild(fileSizeElement);

                if (showDeleteBtn) {
                    newRowElement.appendChild(deleteFileElement);
                    deleteFileElement.appendChild(deleteFileButton);
                    deleteFileButton.appendChild(deleteFileIcon);
                }
            });
        }

    }, [triggerClear]);

    const emptyTemplate = () => {
        return (
            <div className="empty-upload">
                <CloudDownloadIcon />
                <p>
                    <b>Click to upload</b> or drag and drop
                </p>
                <p>Max. File Size: {FILE_UPLOAD_MAX_SIZE / 1048576} MB</p>
            </div>
        );
    };

    const defaultUploadHandler = (e) => {
        onChangeFunction(name, e.files[0], category, formData, setFormData);

        // if we don't have multiple files clear the list
        if (!multiple) {
            const uploadParentElement = document.getElementById(name),
                contentElement = uploadParentElement.querySelector('.custom-added-files');

            if (contentElement) {
                contentElement.innerHTML = '';
            }
        }
    };

    const defaultFileRemove = (e: object): void => {
        onChangeFunction(name, null, category, formData, setFormData);

        // if we don't have multiple files clear the list
        if (!multiple) {
            const uploadParentElement = document.getElementById(name);

            if (uploadParentElement) {
                const contentElement = uploadParentElement.querySelector('.custom-added-files');

                if (contentElement) {
                    contentElement.innerHTML = '';
                }
            }
        }
    };

    const inputField = (
        <div className="general-file-upload">
            {loading && (
                <div className={inputClasses}>
                    <Button label="Upload file" icon="pi pi-cloud-upload" iconPos="left" loading loadingIcon="pi pi-spin pi-sun" />
                </div>
            )}
            {!loading && (
                <FileUpload
                    name="files[]"
                    id={name}
                    className={inputClasses}
                    contentClassName={contentClassName}
                    mode={mode}
                    ref={fileUploadRef}
                    multiple={multiple}
                    auto={auto}
                    accept={accept}
                    // maxFileSize={maxFileSize}
                    customUpload
                    emptyTemplate={emptyTemplate}
                    uploadHandler={uploadHandler || defaultUploadHandler}
                    onRemove={onFileRemove || defaultFileRemove}
                    itemTemplate={itemTemplate}
                    disabled={disabled}
                    chooseOptions={chooseOptions}
                    {...customProps}
                />
            )}
        </div>
    );

    return (
        label
            ? <div className={(!noMargins ? ('mb-' + (bottomMargin || '5')) : '') + (infoMessage ? ' relative' : '')}>
                <span className={wrapClasses}>
                    {label && <label htmlFor={name} className={labelClasses} title={labelClassName == 'one-line' ? label : null}>{label}</label>}

                    {infoMessage && <div className="top-right-icon">
                        <InfoTooltipTemplate target={'tooltip-' + name} message={infoMessage} className="ml-2" />
                    </div>}

                    <div className="input-wrap">
                        {inputField}
                        {errorMessage}
                    </div>
                </span>
            </div>
            : inputField
    );
};

export default FileUploadTemplate;
