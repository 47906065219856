/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivityLogEvent } from './ActivityLogEvent';
import {
    ActivityLogEventFromJSON,
    ActivityLogEventFromJSONTyped,
    ActivityLogEventToJSON,
} from './ActivityLogEvent';
import type { ActivityLogName } from './ActivityLogName';
import {
    ActivityLogNameFromJSON,
    ActivityLogNameFromJSONTyped,
    ActivityLogNameToJSON,
} from './ActivityLogName';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface RootCauseActivityLog
 */
export interface RootCauseActivityLog {
    /**
     * 
     * @type {number}
     * @memberof RootCauseActivityLog
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RootCauseActivityLog
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RootCauseActivityLog
     */
    description?: string;
    /**
     * 
     * @type {User}
     * @memberof RootCauseActivityLog
     */
    user?: User;
    /**
     * 
     * @type {ActivityLogName}
     * @memberof RootCauseActivityLog
     */
    log_name?: ActivityLogName;
    /**
     * 
     * @type {ActivityLogEvent}
     * @memberof RootCauseActivityLog
     */
    event?: ActivityLogEvent;
    /**
     * 
     * @type {Date}
     * @memberof RootCauseActivityLog
     */
    created_at?: Date;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof RootCauseActivityLog
     */
    properties?: Array<Array<string>>;
}

/**
 * Check if a given object implements the RootCauseActivityLog interface.
 */
export function instanceOfRootCauseActivityLog(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RootCauseActivityLogFromJSON(json: any): RootCauseActivityLog {
    return RootCauseActivityLogFromJSONTyped(json, false);
}

export function RootCauseActivityLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): RootCauseActivityLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'log_name': !exists(json, 'log_name') ? undefined : ActivityLogNameFromJSON(json['log_name']),
        'event': !exists(json, 'event') ? undefined : ActivityLogEventFromJSON(json['event']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
    };
}

export function RootCauseActivityLogToJSON(value?: RootCauseActivityLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'user': UserToJSON(value.user),
        'log_name': ActivityLogNameToJSON(value.log_name),
        'event': ActivityLogEventToJSON(value.event),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toISOString().substring(0,10)),
        'properties': value.properties,
    };
}

