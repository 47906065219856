import React from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import { createGlobalStyle } from "styled-components";
import FcaRootCauseMappingForm, {
    FcaRootCauseMappingFormProps
} from "@/components/Core/RootCause/FcaRootCauseMappingForm";

export type FcaRootCauseMappingModalProps = {
    dialogProps: DialogProps
    fcaRootCauseMappingFormProps: FcaRootCauseMappingFormProps
};

const RootCauseModalStyle = createGlobalStyle`
    .fca-root-cause-form {
        width: 100%;
    }
`;

const FcaRootCauseMappingModal: React.FC<FcaRootCauseMappingModalProps> = ({
    dialogProps,
    fcaRootCauseMappingFormProps
}: FcaRootCauseMappingModalProps) => {

    return (
        <>
            <RootCauseModalStyle/>
            <Dialog
                draggable={false}
                blockScroll
                {...dialogProps}
            >
                <FcaRootCauseMappingForm
                    {...fcaRootCauseMappingFormProps}
                />
            </Dialog>
        </>
    );
};

export default FcaRootCauseMappingModal;
