import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { SearchAddressApiClient } from "@/Service/Api/ApiClients/SearchAddressApiClient";
import { RetrieveAddressRequest } from "@/stub";

export type useRetrieveAddressParams = {
    requestParams: RetrieveAddressRequest
    enabled?: boolean
    savePreviousData?: boolean
};
export const useRetrieveAddress = ({
    enabled,
    requestParams,
    savePreviousData,
}: useRetrieveAddressParams) => useQuery({
    ...QueryKeys.searchAddress.detail(requestParams.id),
    queryFn: async () => await SearchAddressApiClient.retrieveAddress(requestParams),
    placeholderData: savePreviousData ? keepPreviousData : undefined,
    enabled
});
