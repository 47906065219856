import React, { useMemo, useState } from 'react';
import {
    DataTable,
    type DataTablePassThroughOptions,
    type DataTableProps,
    type DataTableStateEvent
} from 'primereact/datatable';
import AnglesSort from '@/components/Icons/AnglesSort';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import {
    type Complaint, ComplaintSortBy, SortOrder as ApiSortOrder,
    type GetComplaintsRequest,
    User, ComplaintStateEnum, OAFosState, ComplaintFos, ActionStateEnum
} from '@/stub';
import styled from 'styled-components';
import { SortOrder } from 'primereact/api';
import { produce } from 'immer';
import { formatToUKDate } from '@/Util/formatToUKDate';
import { dayStart } from '@formkit/tempo';
import { clsx } from 'clsx';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import UserSelect from "@/components/Core/Form/Selector/UserSelect";
import { authUserCan } from "@/Util/permissionChecks";
import { useUpdateComplaintAssignment } from "@/Service/Api/ApiHooks/Complaint/useUpdateComplaintAssignment";
import { useAddToast } from "@/Hooks/useAddToast";
import { ComplaintAssignedMessage } from "@/Messages/Toast/Complaint/ComplaintAssignedMessage";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { useAuthUser } from "@/Hooks/useAuthUser";
import { snakeToNormalCase, url } from "@/helpers/general";
import { useOrgId } from "@/Hooks/useOrgId";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDeleteComplaint } from "@/Service/Api/ApiHooks/Complaint/useDeleteComplaint";
import { ComplaintDeletedMessage } from "@/Messages/Toast/Complaint/ComplaintDeletedMessage";
import { useUpdateSearchParams } from "@/Hooks/useUpdateSearchParams";
import useGetInboxFiltersQuery, { InboxFilterType } from "@/Hooks/useGetInboxFiltersQuery";
import { useGetFosComplaints } from "@/Service/Api/ApiHooks/ComplaintFos/useGetFosCompaints";

const today = new Date();

const adjudicationCaseFileDueDateBodyRender = (complaint: ComplaintFos) => {
    if (complaint.case_requested_date_due) {
        const isOverdue = dayStart(complaint.case_requested_date_due) < today;
        const comparisonDate = complaint.case_submitted || complaint.case_requested_date_due;

        if (complaint.case_submitted) {
            return (
                <span className="signalizing-field success">
                    Submitted: {formatToUKDate(complaint.case_submitted)}
                </span>
            );
        }

        return (
            <span className={clsx('signalizing-field', { warning: isOverdue, success: !isOverdue })}>
                {isOverdue ? 'Overdue' : 'Due'}: {formatToUKDate(comparisonDate)}
            </span>
        );
    }

    return <span className="signalizing-field">No date</span>;
};

const finalDecisionCaseFileDueDateBodyRender = (complaint: ComplaintFos) => {
    if (complaint.further_info_due_date) {
        const isOverdue = dayStart(complaint.further_info_due_date) < today;
        const comparisonDate = complaint.further_info_date_submitted || complaint.further_info_due_date;

        if (complaint.further_info_date_submitted) {
            return (
                <span className="signalizing-field success">
                    Submitted: {formatToUKDate(complaint.further_info_date_submitted)}
                </span>
            );
        }

        return (
            <span className={clsx('signalizing-field', { warning: isOverdue, success: !isOverdue })}>
                {isOverdue ? 'Overdue' : 'Due'}: {formatToUKDate(comparisonDate)}
            </span>
        );
    }

    return <span className="signalizing-field">No date</span>;
};


type TableColumnDefinition = {
    label: string
    sortable?: boolean
    field: string
    sortField?: string
    headerTooltip?: string
    body?: React.ReactNode | ((data: Complaint, options: ColumnBodyOptions) => React.ReactNode)
};

export const getLabelForFosState = (state: OAFosState): string => {
    const labelMap = {
        [OAFosState.NotificationReceived]: 'FOS Notification Received',
        [OAFosState.AdjudicationRequested]: 'Adjudication Open - Case File Requested',
        [OAFosState.AdjudicationSubmitted]: 'Adjudication Open - Case File Submitted',
        [OAFosState.FinalDecisionRequested]: 'Final Decision Open - Case File Requested',
        [OAFosState.FinalDecisionSubmitted]: 'Final Decision Open - Case File Submitted',
        [OAFosState.AdjudicationClosed]: 'Adjudication Closed',
        [OAFosState.FinalDecisionClosed]: 'Final Decision Closed',
    };

    return labelMap[state] || 'No FOS';
};

const baseColumns: TableColumnDefinition[] = [
    {
        label: 'Raised Date',
        sortable: true,
        field: 'raised_date',
        body: (data: Complaint) => {
            return formatToUKDate(data.complaint_fos?.case_requested_date_received);
        }
    },
    {
        label: 'Next Action',
        sortable: false,
        field: 'next_action_date',
        body: (complaint: Complaint) => {
            const action = complaint.actions.find((action) => action.state !== ActionStateEnum.Closed);
            if (action) {
                const isOverdue = dayStart(action.due_date) < today;
                const actionDescription = snakeToNormalCase(action.type);
                return (
                    <span
                        className={clsx('signalizing-field', { warning: isOverdue })}
                    >
                        {actionDescription} - {formatToUKDate(action.due_date)}
                    </span>
                );
            }
            return (
                <span className="signalizing-field">No action</span>
            );
        }
    },
    {
        label: 'Days Open',
        sortable: true,
        field: 'days_open',
        headerTooltip: 'Total Days / Working Days',
        body: (complaint: Complaint) => {
            return (
                <span
                >
                    {complaint.complaint_fos?.days_open}
                </span>
            );
        }
    },
    {
        label: 'FOS Status',
        sortable: true,
        field: 'fos_status',
        sortField: 'state',
        body: (complaint: Complaint) => {
            const result = complaint?.complaint_fos?.state ? Object.values(complaint?.complaint_fos?.state).join('') : null;

            return (
                <p>
                    {getLabelForFosState(result)}
                </p>
            );
        }
    },
    {
        label: 'Complaint Reference',
        sortable: true,
        field: 'ref_number',
        sortField: 'internal_id',
    },
    {
        label: 'Customer',
        sortable: true,
        field: 'main_customer_name',
        body: (complaint: Complaint) => {
            return (
                <div>
                    {
                        (complaint.main_customer?.is_hec || complaint.main_customer?.is_vulnerable) &&
                        <div className="flex gap-2 mb-1">
                            {complaint.main_customer.is_hec && <Tag value='High Escalation' severity='danger'/>}
                            {complaint.main_customer.is_vulnerable && <Tag value='Vulnerable' severity='info'/>}
                        </div>
                    }
                    <div>{`${complaint.main_customer?.firstname} ${complaint.main_customer?.middlename ?? ''} ${complaint.main_customer?.surname ?? ''}`}</div>
                </div>
            );
        }
    },
    {
        label: 'Code',
        sortable: true,
        field: 'root_cause_code',
        body: (complaint: Complaint) => {
            return (
                <>
                    <Tooltip target={`.code-info-${complaint.id}`}/>
                    <div className="flex gap-1">
                        <span>{complaint.root_cause.code}</span>
                        <i
                            className={clsx('pi pi-info-circle code-info-icon', `code-info-${complaint.id}`)}
                            data-pr-tooltip={`${complaint.root_cause.parent_names} > ${complaint.root_cause.label}`}
                        ></i>
                    </div>
                </>
            );
        }
    },
    {
        label: 'Owner',
        sortable: true,
        field: 'owner.display_name',
        sortField: 'owner_display_name'
    },
    {
        label: 'Adjudication - Case file Due Date',
        sortable: false,
        body: (complaint: Complaint) => {
            return adjudicationCaseFileDueDateBodyRender(complaint.complaint_fos);
        },
        field: ''
    },
    {
        label: 'Final Decision - Case file Due Date',
        sortable: false,
        body: (complaint: Complaint) => {
            return finalDecisionCaseFileDueDateBodyRender(complaint.complaint_fos);
        },
        field: ''
    }

];

type TableFilterOptions = {
    first: number
    rows: number
    sortField?: string
    sortOrder: SortOrder
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.div`
    .datatable-container {
        margin-top: 2rem;
    }

    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .angles-sort {
        margin-left: 0.3rem;

        &__icon {
            color: var(--primary-200);
            font-size: 0.8rem;
            font-weight: 600;

            &.active {
                color: var(--primary-500);
            }
        }
    }

    .signalizing-field {
        padding: 0.15rem 0.5rem;
        border-radius: 6px;
        text-wrap: nowrap;

        &.future {
            background-color: var(--teal-200)
        }

        &.success {
            background-color: var(--green-200);
        }

        &.warning {
            background-color: var(--yellow-100);
        }

        &.danger {
            background-color: var(--red-200);
        }
    }

    .code-info-icon {
        color: var(--primary-500);
    }
`;

export type ComplaintsDatatableProps = DataTableProps<Complaint[]> & {
    requestParams?: Partial<GetComplaintsRequest>
    frozenData?: boolean
};

const ComplaintsFosDatatable = ({
    requestParams,
    frozenData = false,
    ...props
}: ComplaintsDatatableProps) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const authUser = useAuthUser();
    const orgId = useOrgId();
    const addToast = useAddToast();
    const updateFilterHandler = useUpdateSearchParams();
    const [tableFilters, setTableFilters] = useState<TableFilterOptions>({
        first: 0,
        rows: 10,
        sortField: ComplaintSortBy.RaisedDate,
        sortOrder: SortOrder.DESC
    });

    const {
        pageQuery,
        pageSizeQuery,
        sortFieldQuery,
        sortOrderQuery
    } = useGetInboxFiltersQuery();

    const onPagination = (e: DataTableStateEvent) => {
        setTableFilters(produce(draft => {
            if (draft.rows !== e.rows) {
                draft.first = 0;
            } else {
                draft.first = e.first;
            }
            draft.rows = e.rows;
        }));
        updateFilterHandler(InboxFilterType.Page, ((e.first / e.rows) + 1).toString());
        updateFilterHandler(InboxFilterType.PageSize, e.rows.toString());

    };

    const onSort = (e: DataTableStateEvent) => {
        updateFilterHandler(InboxFilterType.SortField, e.sortField);
        updateFilterHandler(InboxFilterType.SortOrder, e.sortOrder ?? SortOrder.UNSORTED);
        setTableFilters(produce(draft => {
            draft.sortField = e.sortField;
            draft.sortOrder = e.sortOrder ?? SortOrder.UNSORTED;
        }));
    };

    const complaintsRequest = useMemo<GetComplaintsRequest>(() => {
        let sortOrder = undefined;

        if (Number(sortOrderQuery) === SortOrder.ASC) {
            sortOrder = ApiSortOrder.Asc;
        } else if (Number(sortOrderQuery) === SortOrder.DESC) {

            sortOrder = ApiSortOrder.Desc;
        }


        return {
            sort_by: sortFieldQuery as ComplaintSortBy || ComplaintSortBy.RaisedDate,
            sort_order: sortOrder || ApiSortOrder.Desc,
            page: pageQuery ? parseInt(pageQuery) : 1,
            limit: pageSizeQuery ? parseInt(pageSizeQuery) : 10,
            ...requestParams,
        };
    }, [sortFieldQuery, sortOrderQuery, pageQuery, pageSizeQuery, requestParams]);

    const { data: complaints, isLoading } = useGetFosComplaints({
        requestParameters: complaintsRequest,
        savePreviousData: true
    });

    const [complaintDeleteCandidate, setComplaintDeleteCandidate] = useState<Complaint>();

    const updateComplaintAssignmentMutation = useUpdateComplaintAssignment();

    const tableColumns = useMemo(() => {
        const columns = baseColumns.map((baseColumn,) => {
            if (!frozenData) {
                if (
                    baseColumn.field === 'owner.display_name'
                    && authUserCan('assign:org_complaints')
                ) {
                    return {
                        ...baseColumn,
                        body: (complaint: Complaint) => {
                            return <UserSelect
                                disabled={complaint.state === ComplaintStateEnum.Closed}
                                value={complaint.owner}
                                onFocus={(e) => e.target.select()}
                                onSelect={(e) => {
                                    e.originalEvent.preventDefault();
                                    const newOwner: User = e.value;
                                    if (newOwner.id !== complaint.owner.id) {
                                        updateComplaintAssignmentMutation.mutate(
                                            {
                                                complaint_id: complaint.id,
                                                UpdateComplaintAssignRequest: {
                                                    assigned_to_id: newOwner.id
                                                },
                                            },
                                            {
                                                onSuccess: () => {
                                                    queryClient.invalidateQueries({
                                                        queryKey: QueryKeys.complaints._def
                                                    });
                                                    addToast(ComplaintAssignedMessage);
                                                }
                                            }
                                        );
                                    }
                                }}
                            />;
                        }
                    };
                }
            }
            return baseColumn;
        });
        return columns;
    }, [addToast, authUser?.id, frozenData, queryClient, updateComplaintAssignmentMutation]);

    const onComplaintSelect = (complaint: Complaint) => {
        if (
            authUserCan('read:org_complaint_detail')
            || authUser?.id === complaint.owner.id
        ) {
            navigate(url('complaints.view', {
                orgId,
                complaintId: complaint.id
            }));
        }
    };

    const {
        mutate: deleteComplaint,
        isPending: complaintDeleteIsPending
    } = useDeleteComplaint();

    const onComplaintDelete = () => {
        if (complaintDeleteCandidate) {
            deleteComplaint(
                { complaint_id: complaintDeleteCandidate.id },
                {
                    onSuccess: () => {
                        setComplaintDeleteCandidate(undefined);
                        addToast(ComplaintDeletedMessage);
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.complaints._def
                        });
                    }
                }
            );
        }
    };


    const first = pageSizeQuery && pageQuery ?  Number(pageSizeQuery)*Number(pageQuery) - 10 : 0;
    const rows = pageSizeQuery ? Number(pageSizeQuery) : 10;

    return (
        <StyledWrap>
            <div className="datatable-container">
                <DataTable
                    <Complaint[]>
                    lazy
                    loading={isLoading}
                    value={complaints?.data}
                    totalRecords={complaints?.meta.total}
                    selectionMode={'single'}
                    paginator
                    scrollable
                    rows={rows}
                    rowsPerPageOptions={[10, 20, 50]}
                    first={first}
                    pt={dataTablePtOptions}
                    sortIcon={(options) => {
                        return <AnglesSort sortOrder={options.sortOrder} sorted={options.sorted}/>;
                    }}
                    sortField={sortFieldQuery || ComplaintSortBy.RaisedDate}
                    sortOrder={Number(sortOrderQuery) || SortOrder.DESC}
                    onPage={e => {
                        onPagination(e);
                    }}
                    onSort={e => {
                        onSort(e);
                    }}
                    onSelectionChange={(e) => {
                        onComplaintSelect(e.value);
                    }}
                    {...props}
                >
                    {tableColumns.map(
                        column => <Column
                            key={column.label}
                            field={column.field}
                            body={column.body}
                            header={column.label}
                            sortable={column.sortable}
                            sortField={column.sortField}
                            headerTooltip={column.headerTooltip}
                        />
                    )}
                </DataTable>
                <Dialog
                    header="Confirm delete"
                    draggable={false}
                    visible={!!complaintDeleteCandidate}
                    onHide={() => {
                        setComplaintDeleteCandidate(undefined);
                    }}
                >
                    <div className="flex flex-column">
                        <p>Do you want to delete this complaint - ID: {complaintDeleteCandidate?.ref_number} ?</p>
                        <div className="flex gap-2">
                            <Button
                                label="No, cancel"
                                severity="secondary"
                                loading={complaintDeleteIsPending}
                                onClick={() => {
                                    setComplaintDeleteCandidate(undefined);
                                }}
                            />
                            <Button
                                label="Yes"
                                severity="danger"
                                loading={complaintDeleteIsPending}
                                onClick={() => {
                                    onComplaintDelete();
                                }}
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </StyledWrap>
    );
};

export default ComplaintsFosDatatable;
