// General functions helper

import axios from 'axios';
import moment from 'moment';
import { FLASH_MESSAGES_DURATION } from '@/config/constants';
import { routes } from '@/config/routes';
import { clearStorage } from './storage';

/**
 * Change dates from timezone format to UTC format
 *
 * @param {object} data which needs parsing
 *
 * @returns {object} data parsed
 */
export const parseDatesUTC = (data) => {
    for (const key in data) {
        if (typeof (data[key]) === 'object' && moment.isDate(data[key])) {
            data[key] = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
        }
    }

    return data;
};

/**
 * Make customized api requests using axios
 *
 * @param {string} type the type of the request (get, post, delete etc)
 * @param {string} route route of the request (gets appended to the api base url)
 * @param {object} params optional object with data parameter, onSuccess and onFailure callback functions
 * @param {object|null} accessToken authenticated user access token
 */
export const apiRequest = async (type, route, params, accessToken = null) => {
    const requestParams = {
        method: type,
        url: import.meta.env.VITE_API_URL + route,
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    };

    if (params.data) {
        params.data = parseDatesUTC(params.data);

        for (const key in params.data) {
            if (typeof (params.data[key]) === 'undefined') {
                params.data[key] = null;
            }
        }

        if (type === 'get') {
            requestParams.params = params.data;

        } else {
            requestParams.data = params.data;
        }
    }

    await axios(requestParams)
        .then((response) => {
            if (params.onSuccess) {
                const customResponse = {
                    data: null,
                    result: null,
                    pagination: null
                };

                if (response) {
                    if (response.data) {
                        customResponse.data = response.data;

                        if (response.data?.data) {
                            // only one item
                            customResponse.result = response.data.data;

                            // paginated items
                            if (response.data.data?.data && response.data.data?.per_page) {
                                customResponse.result = response.data.data.data;
                            }

                            if (response.data.data?.total) {
                                customResponse.pagination = {};

                                customResponse.pagination.totalEntries = response.data.data.total;
                                customResponse.pagination.totalPages = response.data.data.total;
                            }
                        }

                        // if (response.data?.data?.data) {
                        //     customResponse.result = response.data.data.data;
                        // }

                        // if (response.data?.data?.total) {
                        //     customResponse.pagination = {};

                        //     customResponse.pagination.totalEntries = response.data.data.total;
                        //     customResponse.pagination.totalPages = response.data.data.total;
                        // }

                        if (response.data?.appVersion) {
                            localStorage.setItem('api_version', response.data?.appVersion);
                        }
                    }
                }

                params.onSuccess(customResponse);
            }
        })
        .catch(async (error) => {
            if (error?.response?.status && error.response.status === 401) {
                console.log('no auth', error);
                clearStorage();
                window.location.href = import.meta.env.VITE_SITE_URL;
            } else {
                if (params.onFailure) {
                    const customError = {
                        data: null,
                        response: null,
                        responseData: null,
                        status: null,
                        messages: null
                    };

                    if (error) {
                        customError.data = error;

                        if (error.response) {
                            customError.response = error.response;

                            if (error.response.data) {
                                customError.responseData = error.response.data;

                                if (error.response.data.errorMessage) {
                                    customError.messages = error.response.data.errorMessage;
                                }
                            }

                            if (error.response.status) {
                                customError.status = error.response.status;
                            }
                        }
                    }

                    params.onFailure(customError);
                }
            }
        });
};

const findRoute = (name: string, params, type: 'public' | 'private') => {
    const findRoute = routes[type].find((item) => item.name === name);
    let path = findRoute?.path;
    if (!path) {
        throw new Error(`Route - ${name} not found`);
    }
    if (path && params) {
        for (const key in params) {
            path = path.replace(new RegExp(`:${key}\\??`), params[key]);
        }
    }
    return path;
};

export const url = (name: string, params = {}) => {
    return findRoute(name, params, 'private');
};

/**
 * Shows a notification message in the top right corner of the screen
 *
 * @param {object} ref html dom reference object
 * @param {string} type the type of message: success/info/warn/error
 * @param {string} message the message to be displayed
 */
export const toast = (ref, type, message) => {
    const title = type.charAt(0).toUpperCase() + type.slice(1);

    ref?.current?.show({
        severity: type,
        title,
        detail: message,
        life: FLASH_MESSAGES_DURATION
    });
};

/**
 *
 * @param url
 * @param accessToken
 * @returns {Promise<Response>}
 */
export const fetchWithAuthentication = async (url, accessToken) => {
    const headers = new Headers();

    headers.set('Authorization', 'Bearer ' + accessToken);

    return await fetch(url, { headers });
};

/**
 *
 * @param imageUrl
 * @param accessToken
 * @returns {Promise<string>}
 */
export const getAuthImage = async (imageUrl, accessToken) => {
    // Fetch the image.
    const response = await fetchWithAuthentication(imageUrl, accessToken);

    // Create an object URL from the data.
    const blob = await response.blob();
    return URL.createObjectURL(blob);
};

/**
 * Format a file size
 *
 * @param {integer} bytes the file size in bytes
 *
 * @returns {string} the formatted size
 */
export const formatFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 B';
    }

    if (bytes) {
        const k = 1000,
            dm = 3,
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
};

/**
 * Convert a string from snake case to normal text with uppercase first letter words
 *
 * @param {string} inputString which needs parsing
 *
 * @returns {string} outputString data parsed
 */
export const snakeToNormalCase = (inputString) => {
    let outputString = inputString.toLowerCase();
    const outputArray = outputString.split('_');

    for (let i = 0; i < outputArray.length; i++) {
        outputArray[i] = outputArray[i].charAt(0).toUpperCase() + outputArray[i].slice(1);
    }

    outputString = outputArray.join(' ');

    return outputString;
};

export const getBrowserType = () => {
    const browserMatch = regexp => {
        return regexp.test(navigator.userAgent);
    };

    if (browserMatch(/opr\//i) || !!window.opr) {
        return 'Opera';
    } else if (browserMatch(/edg/i)) {
        return 'Microsoft Edge';
    } else if (browserMatch(/chrome|chromium|crios/i)) {
        return 'Google Chrome';
    } else if (browserMatch(/firefox|fxios/i)) {
        return 'Mozilla Firefox';
    } else if (browserMatch(/safari/i)) {
        return 'Apple Safari';
    } else if (browserMatch(/trident/i)) {
        return 'Microsoft Internet Explorer';
    } else if (browserMatch(/ucbrowser/i)) {
        return 'UC Browser';
    } else if (browserMatch(/samsungbrowser/i)) {
        return 'Samsung Browser';
    } else {
        return 'Unknown browser';
    }
};

/**
 * Check if the current browser is Firefox
 *
 * @returns {boolean}
 */
export const isFirefox = () => {
    return getBrowserType() === 'Mozilla Firefox';
};

/**
 * Check if the current browser is Safari
 *
 * @returns {boolean}
 */
export const isSafari = () => {
    return getBrowserType() === 'Apple Safari';
};

/**
 * Check if the current browser is Edge
 *
 * @returns {boolean}
 */
export const isEdge = () => {
    return getBrowserType() === 'Microsoft Edge';
};

/**
 * Capitalize ONLY the first letter of a string
 *
 * @param {any} testString a given string
 *
 * @returns {string}
 */
export const capitalizeString = (testString) => {
    const str = String(testString);

    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};

/**
 * Set the page's title
 *
 * @param {string} title the new title
 */
export const setDocumentTitle = (title) => {
    document.title = title + ' · Forseti';
};
