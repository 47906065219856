import { DefaultError, keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { SearchAddressApiClient } from "@/Service/Api/ApiClients/SearchAddressApiClient";
import { AddressSuggestion, SearchAddressRequest } from "@/stub";

export type useSearchAddressParams = {
    requestParams: SearchAddressRequest
    enabled?: boolean
    savePreviousData?: boolean
    select?: (suggestions: AddressSuggestion[]) => AddressSuggestion[]
};
export const useSearchAddress = ({
    enabled,
    requestParams,
    savePreviousData,
    select
}: useSearchAddressParams): UseQueryResult<AddressSuggestion[], DefaultError> => useQuery({
    ...QueryKeys.searchAddress.list(requestParams),
    queryFn: async () => await SearchAddressApiClient.searchAddress(requestParams),
    placeholderData: savePreviousData ? keepPreviousData : undefined,
    enabled,
    select
});
