/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcaRootCauseTree
 */
export interface FcaRootCauseTree {
    /**
     * 
     * @type {number}
     * @memberof FcaRootCauseTree
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FcaRootCauseTree
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof FcaRootCauseTree
     */
    parent_names: string;
    /**
     * 
     * @type {boolean}
     * @memberof FcaRootCauseTree
     */
    selectable: boolean;
    /**
     * 
     * @type {Array<FcaRootCauseTree>}
     * @memberof FcaRootCauseTree
     */
    children?: Array<FcaRootCauseTree>;
}

/**
 * Check if a given object implements the FcaRootCauseTree interface.
 */
export function instanceOfFcaRootCauseTree(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "parent_names" in value;
    isInstance = isInstance && "selectable" in value;

    return isInstance;
}

export function FcaRootCauseTreeFromJSON(json: any): FcaRootCauseTree {
    return FcaRootCauseTreeFromJSONTyped(json, false);
}

export function FcaRootCauseTreeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcaRootCauseTree {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'parent_names': json['parent_names'],
        'selectable': json['selectable'],
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(FcaRootCauseTreeFromJSON)),
    };
}

export function FcaRootCauseTreeToJSON(value?: FcaRootCauseTree | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'parent_names': value.parent_names,
        'selectable': value.selectable,
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(FcaRootCauseTreeToJSON)),
    };
}

