import React, { useId } from 'react';
import { Controller } from 'react-hook-form';
import { type BaseFormInputProps } from '@/Type/BaseFormInputProps';
import ErrorsBlock from '@/components/Core/Form/ErrorsBlock';
import { InputNumber, type InputNumberProps } from 'primereact/inputnumber';
import { fieldsPlaceholders } from "@/config/forms";

export type BaseNumberInputProps = InputNumberProps & BaseFormInputProps;

const BaseNumberInput: React.FC<BaseNumberInputProps> = ({
    control,
    label,
    errorMessages,
    className,
    pt,
    ...props
}: BaseNumberInputProps) => {
    const localId = useId();

    return (
        <div className={className}>
            <label className='block mb-2' htmlFor={localId}>{label}{props.required ? '*' : ''}</label>
            {
                control && props.name &&
                <Controller
                    name={props.name}
                    control={control}
                    disabled={props.disabled}
                    render={({ field, fieldState }) => (
                        <InputNumber
                            pt={{
                                input: {
                                    root: {
                                        autocomplete: 'off'
                                    }
                                }
                            }}
                            {...props}
                            {...field}
                            id={localId}
                            invalid={fieldState.invalid}
                            placeholder={props.placeholder ?? fieldsPlaceholders.float}
                            onChange={(e) => {
                                field.onChange(e.value);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                if (props.onBlur) {
                                    props.onBlur(e);
                                }
                            }}
                        />
                    )}
                />
            }
            {
                !control &&
                <InputNumber
                    {...props}
                    className={className}
                    id={localId}
                />
            }
            <ErrorsBlock
                className="block mt-2 p-error"
                errorMessages={errorMessages}
            />
        </div>
    );
};

export default BaseNumberInput;
