import { useQuery } from '@tanstack/react-query';
import { GetRootCausesConfigDownloadDetailsRequest } from '@/stub';
import { rootCauseApiClient } from '@/Service/Api/ApiClients/RootCauseApiClient';
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";

export type useGetRootCausesConfigDownloadDetailsParams = {
    requestParams?: GetRootCausesConfigDownloadDetailsRequest,
    enabled?: boolean,
};

export const useGetRootCausesConfigDownloadDetails = ({
    requestParams,
    enabled = true,
}: useGetRootCausesConfigDownloadDetailsParams) => useQuery({
    ...QueryKeys.rootCauses.configDownloadDetails(requestParams),
    queryFn: async () => await rootCauseApiClient.getRootCausesConfigDownloadDetails(requestParams),
    enabled: enabled,
    retry: false,
});
