import React from 'react';
import BaseTextInput from '@/components/Core/Form/BaseTextInput';
import { useForm } from 'react-hook-form';
import { clsx } from 'clsx';
import { z } from 'zod';
import { Button } from 'primereact/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { AdditionRole, MainRole } from "@/stub";
import { Divider } from "primereact/divider";
import BaseMultiselect from "@/components/Core/Form/BaseMultiselect";
import BaseDropdown from "@/components/Core/Form/BaseDropdown";
import { useValidationErrors } from "@/Hooks/useValidationErrors";
import { authUserCan } from "@/Util/permissionChecks";

const UserFormSchema = z.object({
    firstname: z.string({
        required_error: 'First Name is required'
    }),
    middlename: z.string().optional(),
    surname: z.string({
        required_error: 'Surname is required'
    }),
    display_name: z.string({
        required_error: 'Display Name is required'
    }),
    email: z.string({
        required_error: 'Email is required'
    }).email(),
    password: z.string().min(8, 'Password must contain at least 8 characters').optional(),
    role: z.nativeEnum(MainRole),
    additional_roles: z.array(z.nativeEnum(AdditionRole)),
}).superRefine((data, ctx) => {
    if (data.password && !(/\d/.test(data.password))) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Password must contain at least one number.',
            path: ['password']
        });
    }
    if (data.password && data.password.toLowerCase() === data.password) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Password must contain at least one uppercase and one lowercase letter.',
            path: ['password']
        });
    }
});

export type UserFormData = z.infer<typeof UserFormSchema>;

const mainRoleOptions = Object.entries(MainRole).map(([key, value]) => ({
    key,
    value,
    label: key.replace(/([A-Z])/g, ' $1').trim()
}));

const additionalRolesOptions = Object.entries(AdditionRole).map(([key, value]) => ({
    key,
    value,
    label: key.replace(/([A-Z])/g, ' $1').trim() // For better readability
}));

export type UserFormFormProps = {
    className?: string
    onSubmit?: (data: UserFormData) => void
    onSubmitButtonLabel?: string
    onSubmitButtonDisabled?: boolean
    isProcessing?: boolean
    initFormData?: UserFormData & { is_non_sso_user?: boolean }
    errorBag?: Error[]
};

const UserForm: React.FC<UserFormFormProps> = ({
    className,
    onSubmit,
    isProcessing,
    initFormData,
    onSubmitButtonLabel,
    onSubmitButtonDisabled = false,
    errorBag
}: UserFormFormProps) => {
    UserFormSchema.superRefine((data, ctx) => {
        if (!initFormData) {
            if (!data.password) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Password is required',
                    path: ['password']
                });
            }

        } else {
            if (data.password && !(/\d/.test(data.password))) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Password must contain at least one number.',
                    path: ['password']
                });
            }
        }
    });

    const {
        control,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<UserFormData>({
        resolver: zodResolver(UserFormSchema),
        defaultValues: initFormData ?? {
            email: '',
            firstname: '',
            middlename: '',
            surname: '',
            display_name: '',
            password: '',
            role: undefined,
            additional_roles: [],
        }
    });

    const onFormSubmit = (data: UserFormData) => {
        if (onSubmit) {
            onSubmit(data);
        }
    };

    useValidationErrors(setError, errorBag);
    return (
        <div className={clsx(className, 'user-form')}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="grid">
                    <h4 className={'col-12 my-0 font-bold'}>Personal info</h4>
                    <BaseTextInput
                        className={"col-6 rounded-md"}
                        pt={{ inputText: { className: 'w-full' } }}
                        control={control}
                        required
                        name={"firstname"}
                        label={'First Name'}
                        errorMessages={errors.firstname?.message}
                    />
                    <BaseTextInput
                        className={"col-6 rounded-md"}
                        pt={{ inputText: { className: 'w-full' } }}
                        control={control}
                        name={"middlename"}
                        label={'Middle Name'}
                        errorMessages={errors.middlename?.message}
                    />
                    <BaseTextInput
                        className={"col-6 rounded-md"}
                        pt={{ inputText: { className: 'w-full' } }}
                        control={control}
                        required
                        name={"surname"}
                        label={'Last Name'}
                        errorMessages={errors.surname?.message}
                    />
                    <BaseTextInput
                        className={"col-6 rounded-md"}
                        pt={{ inputText: { className: 'w-full' } }}
                        control={control}
                        required
                        name={"display_name"}
                        label={'Display Name'}
                        errorMessages={errors.display_name?.message}
                    />
                    <BaseTextInput
                        className={"col-6 rounded-md"}
                        pt={{ inputText: { className: 'w-full' } }}
                        control={control}
                        required
                        readOnly={!!initFormData}
                        type={"email"}
                        name={"email"}
                        label={'Email'}
                        errorMessages={errors.email?.message}
                    />
                    {
                        (!initFormData || (authUserCan('edit:user_password') && initFormData.is_non_sso_user)) ?
                            <BaseTextInput
                                className={"col-6 rounded-md"}
                                pt={{ inputText: { className: 'w-full' } }}
                                control={control}
                                tooltip={initFormData ? 'Leave it blank if don\'t want to change user\'s password!' : undefined}
                                tooltipOptions={{ position: 'top' }}
                                type={'password'}
                                required={!initFormData}
                                name={"password"}
                                label={'Password'}
                                errorMessages={errors.password?.message}
                            />
                            : undefined
                    }

                    <Divider className={'col-12'}/>
                    <h4 className={'col-12 my-0 font-bold'}>General</h4>
                    <BaseDropdown
                        className={"col-6 rounded-md"}
                        pt={{ dropdown: { className: 'w-full' } }}
                        control={control}
                        options={mainRoleOptions}
                        required
                        name={"role"}
                        label={'Main Role'}
                        errorMessages={errors.role?.message}
                    />
                    <BaseMultiselect
                        className={"col-6 rounded-md"}
                        pt={{ wrapper: { className: 'w-full' } }}
                        control={control}
                        options={additionalRolesOptions}
                        name={"additional_roles"}
                        label={'Additional Roles'}
                        errorMessages={errors.additional_roles?.message}
                    />
                </div>
                <div className="flex mt-4">
                    <Button
                        className={"ml-auto"}
                        label={onSubmitButtonLabel}
                        disabled={onSubmitButtonDisabled}
                        type={"submit"}
                        severity={"success"}
                        loading={isProcessing}
                    />
                </div>
            </form>
        </div>
    );
};

export default UserForm;
