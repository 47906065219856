import React, { useMemo } from 'react';
import BaseTreeSelect, { type BaseTreeSelectProps } from '@/components/Core/Form/BaseTreeSelect';
import type { RootCause } from '@/stub';
import { OptionsTree } from "@/Model/OptionsTree";
import { useFcaGetRootCauses } from "@/Service/Api/ApiHooks/FcaRootCause/useGetFcaRootCauses";

export type BaseRootCauseSelectProps = BaseTreeSelectProps & {
    queryEnabled?: boolean
    selectableLvl?: number
};

const buildRootCauseTree = (rootCauses: RootCause[]): OptionsTree<RootCause>[] => {
    if (rootCauses?.length > 0) {
        return rootCauses.map((rootCause) => {
            return new OptionsTree<RootCause>(
                rootCause.id,
                rootCause,
                rootCause.label,
                rootCause.parent_names,
                rootCause.selectable,
                buildRootCauseTree(rootCause.children ?? [])
            );
        });
    }
    return [];
};

const BaseRootCauseFcaSelect: React.FC<BaseRootCauseSelectProps> = ({
    queryEnabled = false,
    selectableLvl = 1,
    ...props
}) => {
    const {
        data: rootCauses,
        refetch
    } = useFcaGetRootCauses({
        requestParams: { selectable_lvl: selectableLvl },
        select: (rootCauses: RootCause[]) => rootCauses?.[0]?.children ?? [],
        enabled: queryEnabled || !!props.value
    });

    const rootCauseOptions = useMemo(() => {
        if (rootCauses) {
            return buildRootCauseTree(rootCauses);
        }
        return [];
    }, [rootCauses]);

    return (

        <BaseTreeSelect
            filter
            options={rootCauseOptions}
            variant='filled'
            onClick={(e) => {
                if (!queryEnabled && !rootCauses) {
                    refetch();
                }
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
            {...props}
        />
    );
};

export default BaseRootCauseFcaSelect;
