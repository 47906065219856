import { useMutation } from "@tanstack/react-query";
import { CreateUserRequest } from "@/stub";
import { processValidationErrors } from "@/Util/Api/processValidationErrors";
import { usersApiClient } from "@/Service/Api/ApiClients/UsersApiClient";

export const useCreateUser = () => useMutation({
    mutationFn: async (data: CreateUserRequest) => {
        try {
            await usersApiClient.createUser(data);
        } catch (error) {
            await processValidationErrors(error);
            throw error;
        }
    }
});
