import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { type GetComplaintsRequest } from '@/stub';
import { QueryKeys } from '@/Service/Api/QueryKeys/QueryKeys';
import { ComplaintFosApiClient } from "@/Service/Api/ApiClients/ComplaintFosApiClient";

export type useGetComplaintsParams = {
    requestParameters?: GetComplaintsRequest
    enabled?: boolean
    savePreviousData?: boolean
};
export const useGetFosComplaints = ({ requestParameters, enabled, savePreviousData }: useGetComplaintsParams = {}) => useQuery({
    ...QueryKeys.complaints.list(requestParameters),
    queryFn: async () => await ComplaintFosApiClient.getFosComplaints(requestParameters),
    enabled,
    placeholderData: savePreviousData ? keepPreviousData : undefined
});
