/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcaRootCauseTree } from './FcaRootCauseTree';
import {
    FcaRootCauseTreeFromJSON,
    FcaRootCauseTreeFromJSONTyped,
    FcaRootCauseTreeToJSON,
} from './FcaRootCauseTree';
import type { RootCauseStatus } from './RootCauseStatus';
import {
    RootCauseStatusFromJSON,
    RootCauseStatusFromJSONTyped,
    RootCauseStatusToJSON,
} from './RootCauseStatus';

/**
 * 
 * @export
 * @interface RootCause
 */
export interface RootCause {
    /**
     * 
     * @type {number}
     * @memberof RootCause
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RootCause
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof RootCause
     */
    lvl: number;
    /**
     * 
     * @type {number}
     * @memberof RootCause
     */
    parent_id: number;
    /**
     * 
     * @type {FcaRootCauseTree}
     * @memberof RootCause
     */
    fca_root_cause?: FcaRootCauseTree;
    /**
     * 
     * @type {string}
     * @memberof RootCause
     */
    parent_names: string;
    /**
     * 
     * @type {RootCauseStatus}
     * @memberof RootCause
     */
    status: RootCauseStatus;
    /**
     * 
     * @type {boolean}
     * @memberof RootCause
     */
    selectable: boolean;
    /**
     * 
     * @type {Array<RootCause>}
     * @memberof RootCause
     */
    children?: Array<RootCause>;
}

/**
 * Check if a given object implements the RootCause interface.
 */
export function instanceOfRootCause(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "lvl" in value;
    isInstance = isInstance && "parent_id" in value;
    isInstance = isInstance && "parent_names" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "selectable" in value;

    return isInstance;
}

export function RootCauseFromJSON(json: any): RootCause {
    return RootCauseFromJSONTyped(json, false);
}

export function RootCauseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RootCause {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'lvl': json['lvl'],
        'parent_id': json['parent_id'],
        'fca_root_cause': !exists(json, 'fca_root_cause') ? undefined : FcaRootCauseTreeFromJSON(json['fca_root_cause']),
        'parent_names': json['parent_names'],
        'status': RootCauseStatusFromJSON(json['status']),
        'selectable': json['selectable'],
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(RootCauseFromJSON)),
    };
}

export function RootCauseToJSON(value?: RootCause | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'lvl': value.lvl,
        'parent_id': value.parent_id,
        'fca_root_cause': FcaRootCauseTreeToJSON(value.fca_root_cause),
        'parent_names': value.parent_names,
        'status': RootCauseStatusToJSON(value.status),
        'selectable': value.selectable,
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(RootCauseToJSON)),
    };
}

