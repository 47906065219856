import React, { useMemo, useState } from 'react';
import BaseAutocomplete, { type BaseAutocompleteProps } from '@/components/Core/Form/BaseAutocomplete';
import { useGetCustomers } from '@/Service/Api/ApiHooks/Customer/useGetCustomers';
import { CustomerTypeEnum } from "@/stub";
import { Button } from "primereact/button";

export type CustomerSelectProps = BaseAutocompleteProps & {
    types?: CustomerTypeEnum[],
    forceSelection?: boolean
};

const CustomerSelect: React.FC<CustomerSelectProps> = ({
    types,
    forceSelection = true,
    ...props
}: CustomerSelectProps) => {
    const [searchTerm, setSearchTerm] = useState<string>();

    const {
        data: customers
    } = useGetCustomers({
        requestParams: {
            search: searchTerm,
            type: types
        },
        enabled: !!searchTerm,
        // The Primereact Autocomplete component checks for suggestion update only one fucking time
        // If you fucked up an update by setting wrong data there is no turning back - it will never update once again
        // React query does this update: prev_data -> undefined -> new_data so the statement below removes undefined from chain
        savePreviousData: true
    });

    const suggestions = useMemo(() => {
        return customers?.data || [];
    }, [customers]);

    return (
        <div className="flex">
            <BaseAutocomplete
                {...props}
                field="display_name"
                value={props.value ? props.value : searchTerm}
                delay={700}
                suggestions={suggestions}
                forceSelection={forceSelection}
                onClear={(event) => {
                    // This will reset the input value to the initial one
                    if (props.onClear) {
                        props.onClear(event);
                    }
                    setSearchTerm(undefined);
                }}
                onSelect={(e) => {
                    setSearchTerm(undefined);
                    if(props.onSelect) {
                        props.onSelect(e);
                    }
                }}
                completeMethod={(e) => {
                    setSearchTerm(e.query);
                }}
            />
            {props.onClear && (searchTerm ?? props.value) && <Button icon="pi pi-times" className="" onClick={() => {
                props.onClear();
                setSearchTerm(undefined);
            }}
            rounded text aria-label="Cancel" /> }
        </div>

    );
};

export default CustomerSelect;
