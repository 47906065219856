import React, { useEffect, useState } from 'react';
import type { BaseFormInputProps } from '@/Type/BaseFormInputProps';
import { useSearchAddress } from "@/Service/Api/ApiHooks/SearchAddress/useSearchAddress";
import BaseTextInput from "@/components/Core/Form/BaseTextInput";
import BaseAutocomplete, { BaseAutocompleteProps } from "@/components/Core/Form/BaseAutocomplete";
import { Address, AddressSuggestion, AddressSuggestionTypeEnum } from "@/stub";
import { useRetrieveAddress } from "@/Service/Api/ApiHooks/SearchAddress/useRetrieveAddress";
import ContentLoadingTemplate from "@/components/templates/ContentLoadingTemplate";

export type CustomerAddressProps = BaseAutocompleteProps & BaseFormInputProps & {
    onAddressSelect: (address: Address) => void
};

const CustomerAddress: React.FC<CustomerAddressProps> = ({
    control,
    onAddressSelect,
    ...props
}: CustomerAddressProps) => {
    const [searchTerm, setSearchTerm] = useState<string>();
    const [selectedLocation, setSelectedLocation] = useState<AddressSuggestion>();

    const {
        data: suggestions,
        error,
        isLoading: isSearching,
    } = useSearchAddress({
        requestParams: {
            search: searchTerm,
            id: selectedLocation?.id
        },
        enabled: !!searchTerm && selectedLocation && selectedLocation.type !== AddressSuggestionTypeEnum.Address,
        savePreviousData: true
    });

    const {
        data: address,
        isLoading: isRetrieving
    } = useRetrieveAddress({
        requestParams: {
            id: selectedLocation?.id as string
        },
        enabled: !!(selectedLocation && selectedLocation?.type === AddressSuggestionTypeEnum.Address)
    });

    const onAddressClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, address: AddressSuggestion) => {
        setSelectedLocation(address);
        setSearchTerm(address.label);
        if (address.type !== AddressSuggestionTypeEnum.Address) {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    const itemTemplate = (item: AddressSuggestion) => {
        return (
            (isRetrieving || isSearching) ?
                <ContentLoadingTemplate/>
                :
                (<div className="flex align-items-center justify-content-between gap-2" onClick={(e) => {
                    onAddressClick(e, item);
                }}>
                    <span>{item.label}</span>
                    {item.type !== AddressSuggestionTypeEnum.Address && <i className={'pi pi-angle-down'}></i>}
                </div>)
        );
    };

    useEffect(() => {
        if (address) {
            onAddressSelect(address);
        }
    }, [address]);

    return (
        <>
            <div className={'col-12'} style={{ paddingRight: '3rem' }}>
                <BaseAutocomplete
                    {...props}
                    inputClassName={'w-full'}
                    field="label"
                    name='address-search'
                    label="Address Search"
                    placeholder={'Search for an address'}
                    value={searchTerm}
                    delay={700}
                    errorMessages={error?.message}
                    itemTemplate={itemTemplate}
                    suggestions={suggestions}
                    onClear={() => {
                        setSearchTerm(undefined);
                    }}
                    completeMethod={(e) => {
                        setSelectedLocation(undefined);
                        setSearchTerm(e.query);
                    }}
                />
            </div>

            <BaseTextInput
                className="col-6"
                control={control}
                required
                name="address1"
                label="Address Line 1"
                errorMessages={props.errorMessages?.address1?.message}
            />
            <BaseTextInput
                className="col-6"
                control={control}
                name="address2"
                label="Address Line 2"
                errorMessages={props.errorMessages?.address2?.message}
            />
            <BaseTextInput
                className="col-6"
                control={control}
                required
                name="postcode"
                label="Postcode"
                errorMessages={props.errorMessages?.postcode?.message}
            />
            <BaseTextInput
                className="col-6"
                control={control}
                required
                name="city"
                label="City"
                errorMessages={props.errorMessages?.city?.message}
            />
            <BaseTextInput
                className="col-6"
                control={control}
                required
                name="county"
                label="County"
                errorMessages={props.errorMessages?.county?.message}
            />
            <BaseTextInput
                className="col-6"
                control={control}
                required
                name="country"
                label="Country"
                errorMessages={props.errorMessages?.country?.message}
            />
        </>
    );
};

export default CustomerAddress;
